import React from "react";
import {
  Spinner,
  Table,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import axios from "axios";
import { BsSearch, BsPencil } from "react-icons/bs";
import { MdPrint } from "react-icons/md";
import { FcMoneyTransfer } from "react-icons/fc";

import { useHistory } from "react-router";

const pageSize = 15;

const IndexCustomer = () => {
  const history = useHistory();

  const [customer, setCustomer] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const cancelToken = React.useRef(null);
  const searchInput = React.useRef(null);

  //pagination
  const [page, setPage] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [c_name, setCName] = React.useState("");

  // const _token = JSON.parse(localStorage.getItem("token"));

  const getData = async (page) => {
    try {
      if (c_name === "") {
        // setLoading(true)
        setCustomer([]);
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}customer?page=${page}&size=${pageSize}&c_name=${c_name}`;
      const resp = await axios.get(apiUrl, {
        cancelToken: cancelToken.current.token,
      });
      // resp.data.data !== [] && (setCustomer(resp.data.data) );
      // resp.data.meta.status === 'success'  && setTotal(resp.data.meta.pagination.total);
      setCustomer(resp.data.data);
      setTotal(resp.data.meta.pagination.total);
    } catch (error) {
      // console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();

    // getData(page);

    // console.log('use effect')

    searchInput.current.focus();

    // return () => {
    //   cancelToken.current.cancel();
    // };
    const timer = setTimeout(() => {
      getData(page);
    }, 1000);

    return () => {
      clearTimeout(timer);
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, c_name]);

  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        <p>เกิดข้อผิดพลาดจาก Server กรุณาลองใหม่</p>
        <p>{error.response.data.message}</p>
      </div>
    );
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const customerSearch = (change) => {
    // console.log(change.target.value);
    setCName(change.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2>รายชื่อลูกค้า</h2>
            <div className="row">
              <div className="col-sm-8">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    history.push("/customer/create");
                  }}
                >
                  เพิ่มลูกค้า
                </button>
              </div>
              <div className="col-sm-4">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search..."
                    value={c_name}
                    ref={searchInput}
                    onChange={customerSearch}
                    // onChange = {(event) => this.setCName({email: event.target.value })}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <BsSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>รหัสลูกค้า</th>
                  <th>ชื่อ</th>
                  <th>จังหวัด</th>
                  <th>โทรศัพท์</th>
                  <th>เครื่องมือ</th>
                </tr>
              </thead>
              <tbody>
                {customer.map((c, index) => {
                  return (
                    <tr key={c._id}>
                      <td>{c.code}</td>
                      <td>
                        {c.name.length > 55
                          ? c.name.slice(0, 55).concat("...")
                          : c.name}
                      </td>
                      <td>{c.province}</td>
                      <td>{c.tel}</td>
                      <td>
                        <Button
                          className="ml-2"
                          variant="info"
                          size="sm"
                          onClick={() =>
                            history.push(`/customer/edit/${c._id}`)
                          }
                        >
                          <BsPencil />
                        </Button>
                        <Button
                          className="ml-2"
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            window.open(`/customer/pdf/${c._id}`, "_blank")
                          }
                        >
                          <MdPrint />
                        </Button>
                        <Button
                          className="ml-2"
                          variant="success"
                          size="sm"
                          onClick={() =>
                            window.open(`/customer/pdf2/${c._id}`, "_blank")
                          }
                        >
                          <FcMoneyTransfer />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              // prevPageText="ก่อนหน้า"
              firstPageText="หน้าแรก"
              lastPageText="หน้าสุดท้าย"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexCustomer;
