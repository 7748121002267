import { combineReducers } from 'redux'

import authReducer from './authReducer'
import cartReducer from './cartReducer';

const rootReducer = combineReducers({
    authReducer,
    cartReducer,
})

export default rootReducer;