import React from "react";
import { format } from "date-fns";
import {
  Table,
  Spinner,
  Form,
  InputGroup,
  FormControl,
  Card,
  Button,
  Row,
  Col,
  Image,
  Modal,
  Popover,
  OverlayTrigger,
  Accordion,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Select from "react-select";
import {
  BsSearch,
  BsFillTrashFill,
  BsClockHistory,
  BsSaveFill,
  BsJournalText,
  BsArrowRepeat,
  BsBoxArrowLeft,
} from "react-icons/bs";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import typeShipment from "./typeShipment.json";

//redux
import { useHistory } from "react-router";

const schema = yup.object().shape({});

const CreateInvoice = () => {
  const { addToast } = useToasts();
  const MySwal = withReactContent(Swal);
  const [productShow, setProductShow] = React.useState(false);
  const vat = process.env.REACT_APP_VAT_TH;

  //init
  const [total, setTotal] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [discountPercent, setDiscountPercent] = React.useState(0);
  const [freight, setFreight] = React.useState(0);
  const [nowDate, setNowDate] = React.useState(format(new Date(), "yyy-MM-dd"));
  const [remark, setRemark] = React.useState("-");
  const [shipmentOptions, setShipmentOptions] = React.useState([]);

  //INVOICE
  const [po, setPO] = React.useState("-");
  const [c_pay, setCpay] = React.useState(30);
  const [shipment, setShipment] = React.useState("");

  // const shipmentOptions = typeShipment.map((anObjectMapped, index) => {
  //   return { value: anObjectMapped.value, label: anObjectMapped.label };
  // });

  const totalAll = () => {
    return Number(total) - Number(discount) + Number(freight);
  };

  const [recommended, setRecommended] = React.useState([]);

  const [sales, setSales] = React.useState([]);
  const [sale, setSale] = React.useState("");

  const [customer, setCustomer] = React.useState([]);
  const [Customers, setCustomers] = React.useState([]);

  const [p_name, setPname] = React.useState("");
  const [products, setProducts] = React.useState([]);
  //Ref
  const searchInput = React.useRef(null);
  const refInput = React.useRef(null);

  const [lists, setLists] = React.useState([]);
  //redux
  // const cart = useSelector((state) => state.cartReducer.cart);
  // const total = useSelector((state) => state.cartReducer.total);
  // const dispatch = useDispatch();

  const history = useHistory();

  const _token = JSON.parse(localStorage.getItem("token"));
  // const [base64Image, setBase64Image] = React.useState('5555');

  const showToastError = (msg) => {
    // console.log(msg);
    addToast(msg, { appearance: "error" });
  };

  const { control } = useForm({
    resolver: yupResolver(schema),
  });

  const ivSubmit = async () => {
    // console.log(data);
    try {
      //   console.log(nowDate);
      const apiUrl = `${process.env.REACT_APP_API_URL}invoice`;
      const resp = await axios.post(
        apiUrl,
        {
          dateQT: nowDate,
          freight: freight,
          discount: discount,
          discountPercent: discountPercent,
          vat: vat,
          total: totalAll(),
          remark: remark,
          sale: sale.value,
          customer: customer.value,
          lists: lists,
          //   invoice
          shipment: shipment.value,
          c_pay: c_pay,
          po: po,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // alert(resp.data.message);

      addToast(resp.data.message, { appearance: "success" });
      history.replace("/invoice");
    } catch (error) {
      // console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  const getData = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}customer/all`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });

    setCustomers(resp.data.data);

    const apiUrlSale = `${process.env.REACT_APP_API_URL}user/sales`;
    const respSale = await axios.get(apiUrlSale, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });

    setSales(respSale.data.data);
    // console.log(resp.data.data);

    // Seting Api
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Sale`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting.data;
    // console.log(resp_setting.data.setting);

    setShipmentOptions(
      setting_name.Shipment.map((anObjectMapped, index) => {
        return { value: anObjectMapped.value, label: anObjectMapped.label };
      })
    );
    // END Seting Api
  }, [_token.access_token]);
  React.useEffect(() => {
    // console.log("use effect");
    getData();
  }, [getData]);

  const searchProduct = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}product/search/?p_name=${p_name}`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });
    setProducts(resp.data.data);
    // console.log(resp.data.data);
    if(resp.data.data.length === 0){
      // console.log(recommended);
      setProducts(recommended);
    }
  }, [_token.access_token, p_name, recommended]);

  React.useEffect(() => {
    // searchProduct();
    const timer = setTimeout(() => {
      searchProduct();
    }, 1000);

    return () => clearTimeout(timer);
    // searchInput.current.focus();
  }, [searchProduct]);

  const customerOptions = Customers.map((anObjectMapped, index) => {
    return { value: anObjectMapped._id, label: anObjectMapped.name };
  });

  const saleOptions = sales.map((anObjectMapped, index) => {
    return { value: anObjectMapped._id, label: anObjectMapped.name };
  });

  const productSearch = (change) => {
    // console.log(change.target.value);
    setPname(change.target.value);
  };

  const getPrice = async (p, c_id, u) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}quotation/price/`;
    const resp = await axios.post(
      apiUrl,
      {
        product: p._id,
        customer: c_id,
        unit: u,
      },
      {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
      }
    );

    // console.log(resp.data.price);
    // setNowPrice(resp.data.data);
    const product = {
      _id: p._id,
      name: resp.data.name === "" ? p.name : resp.data.name,
      unit: u === "unit_m" ? p.unit_m : p.unit_n,
      price: resp.data.price,
      qty: 1,
      discount: 0,
      detail: "-",
      unitType: u,
      priceHistory: resp.data.data,
    };
    // console.log(product);
    // setNowPrice(0)
    setLists([...lists, product]);
    // console.log(product);
  };

  const setRecommended_1 = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}quotation/recommended_products`;
    const resp = await axios.post(
      apiUrl,
      {
        customer: customer,
      },
      {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
      }
    );
    
    setRecommended(resp.data.data);

  }, [_token.access_token, customer]);

  React.useEffect(() => {
    setRecommended_1();
    // searchInput.current.focus();
  }, [setRecommended_1]);

  const getRecommended = async (c_id) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}quotation/recommended_products`;
    const resp = await axios.post(
      apiUrl,
      {
        customer: c_id,
      },
      {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
      }
    );

    setRecommended(resp.data.data);
    // console.log(resp.data);
    // console.log(product);
  };

  const addListsQT = (p, unit) => {
    // console.log(customer);

    const checkProduct = lists.find(({ _id }) => _id === p._id);
    if (!checkProduct) {
      getPrice(p, customer.value, unit);

      setProductShow(false);
      setPname("");
      calTotal();
    } else {
      showToastError("สินค้าซ้ำ");
    }
  };

  const rmListsQT = (p) => {
    setLists(lists.filter((item) => item._id !== p._id));
  };

  const setTHB = (b) => {
    let bath = Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
    }).format(b);

    return bath.substring(1);
  };

  const updateLists = (_id, c, name) => {
    const value = c.value;
    if (name === "name" || name === "detail") {
      lists.find((v) => v._id === _id)[name] = value;
    } else {
      lists.find((v) => v._id === _id)[name] = Number(value);
    }
    const listsNew = lists;
    calTotal(listsNew);
  };

  const calTotal = (lists) => {
    // console.log(lists)
    if (lists) {
      let sum = lists.reduce((sum, a) => {
        return sum + a.price * a.qty - a.discount;
      }, 0);
      setTotal(sum);
    }
  };

  React.useEffect(() => {
    // console.log(lists)
    calTotal(lists);
  }, [lists]);

  const popover = (history) => {
    return (
      <Popover>
        <Popover.Title>ประวัติราคา</Popover.Title>
        <Popover.Content>
          {history.map((h, index) => {
            return (
              <li key={index}>
                ({format(new Date(h.createdAt), "dd-MM-yy")}) {h.price} บาท
              </li>
            );
          })}
        </Popover.Content>
      </Popover>
    );
  };

  const saveQT = () => {
    let check = true;

    if (sale === "") {
      showToastError("กรุณาเลือกพนักงานขาย");
      check = false;
    }
    if (c_pay === "") {
      showToastError("กรุณากรอกวันกำหนดชำระ");
      check = false;
    }
    if (shipment === "") {
      showToastError("กรุณาเลือกการจัดส่ง");
      check = false;
    }

    if (check) {
      // alert('5555')
      MySwal.fire({
        title: "ยืนยันการบันทึก?",
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "บันทึก",
        cancelButtonText: `ยกเลิก`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          ivSubmit();
        }
      });
    }
  };

  return (
    <>
      <div className="container" style={{ backgroundColor: "HoneyDew" }}>
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2>
              {/* INVOICE */}
              <span style={{ color: "DarkGreen" }}>{` ออกใบกำกับภาษี `}</span>
              <button
                className="btn btn-success ml-3"
                onClick={() => {
                  history.go(0);
                }}
              >
                <BsArrowRepeat />
              </button>

              <button
                className="btn btn-secondary"
                style={{ float: "right" }}
                onClick={() => {
                  history.replace("/invoice");
                }}
              >
                กลับ <BsBoxArrowLeft />
              </button>
            </h2>
            <Controller
              className="mb-2"
              name="customer"
              control={control}
              defaultValue={[]}
              render={() => (
                <Select
                  placeholder="เลือกลูกค้า.."
                  className="mt-3 mb-3"
                  value={customer}
                  options={customerOptions}
                  onChange={(e) => {
                    setCustomer(e);
                    // console.log(e);
                    setCustomers([{ _id: e.value, name: e.label }]);
                    getRecommended(e.value);
                  }}
                />
              )}
            />
            {customer.length === 0 ? (
              <div className="text-center mt-5">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <>
                {/* INVOICE */}
                <Row>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      placeholder="เลขที่ po"
                      onChange={(e) => setPO(e.target.value)}
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Control
                      type="date"
                      value={nowDate}
                      onChange={(e) => {
                        // console.log(nowDate)
                        setNowDate(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={3}>
                    <Controller
                      name="seller"
                      control={control}
                      defaultValue={[]}
                      render={() => (
                        <Select
                          placeholder="พนักงานขาย.."
                          className=""
                          value={sale}
                          options={saleOptions}
                          onChange={(e) => {
                            setSale(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Controller
                      name="shipment"
                      control={control}
                      defaultValue={[]}
                      render={() => (
                        <Select
                          placeholder="เลือกขนส่ง.."
                          className=""
                          value={shipment}
                          options={shipmentOptions}
                          onChange={(e) => {
                            setShipment(e);
                            // console.log(e.value)
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Control
                      type="number"
                      placeholder="กำหนดชำระ (วัน)"
                      value={c_pay}
                      // defaultValue={'30'}
                      onChange={(e) => setCpay(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Button
                      style={{ float: "right" }}
                      className="btn btn-secondary mb-2"
                      onClick={() => {
                        setProductShow(true);
                      }}
                    >
                      <span style={{ color: "GreenYellow" }}>เลือกสินค้า</span>
                    </Button>
                  </Col>
                </Row>
                <Table striped bordered hover>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th style={{ width: "2%" }}>#</th>
                      {/* <th>รหัสสินค้า</th> */}
                      <th>ชื่อสินค้า</th>
                      <th style={{ width: "5%" }}>หน่วย</th>
                      <th style={{ width: "10%" }}>จำนวน</th>
                      <th style={{ width: "15%" }}>ราคา</th>
                      <th style={{ width: "10%" }}>ส่วนลด</th>
                      <th style={{ width: "10%" }}>รวม</th>
                      <th style={{ width: "5%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {lists.length >= 1 &&
                      lists.map((l, index) => {
                        return (
                          <tr key={l._id}>
                            <td>{index + 1}</td>
                            <td>
                              <Accordion>
                                <Card>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      defaultValue={l.name}
                                      onChange={(e) =>
                                        updateLists(
                                          l._id,
                                          e.currentTarget,
                                          "name"
                                        )
                                      }
                                    />
                                    <Accordion.Toggle
                                      as={Button}
                                      variant="link"
                                      eventKey="0"
                                    >
                                      <BsJournalText />
                                    </Accordion.Toggle>
                                  </InputGroup>
                                  <Accordion.Collapse eventKey="0">
                                    <Form.Control
                                      placeholder="รายละเอียด"
                                      type="text"
                                      // defaultValue={l.detail}
                                      onChange={(e) =>
                                        updateLists(
                                          l._id,
                                          e.currentTarget,
                                          "detail"
                                        )
                                      }
                                    />
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            </td>
                            <td>{l.unit}</td>
                            <td>
                              <Form.Control
                                type="number"
                                size="sm"
                                min={1}
                                style={{ textAlign: "right" }}
                                defaultValue={l.qty}
                                onChange={(e) =>
                                  updateLists(l._id, e.currentTarget, "qty")
                                }
                              />
                            </td>
                            <td>
                              <InputGroup>
                                <FormControl
                                  style={{ textAlign: "right" }}
                                  type="number"
                                  defaultValue={l.price}
                                  size="sm"
                                  min={0}
                                  ref={refInput}
                                  onChange={(e) =>
                                    updateLists(l._id, e.currentTarget, "price")
                                  }
                                />
                                <InputGroup.Text>
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="right"
                                    overlay={popover(l.priceHistory)}
                                  >
                                    <BsClockHistory>.</BsClockHistory>
                                  </OverlayTrigger>
                                </InputGroup.Text>
                              </InputGroup>
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                min={0}
                                size="sm"
                                style={{ textAlign: "right" }}
                                defaultValue={l.discount}
                                onChange={(e) =>
                                  updateLists(
                                    l._id,
                                    e.currentTarget,
                                    "discount"
                                  )
                                }
                              />
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <h6>{setTHB(l.price * l.qty - l.discount)}</h6>
                            </td>
                            <td>
                              <Button
                                className="btn-sm"
                                variant="danger"
                                onClick={() => {
                                  rmListsQT(l);
                                }}
                              >
                                <BsFillTrashFill />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    {lists.length >= 1 && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "right" }}>
                          <h6 className="mt-2">ค่าขนส่ง</h6>
                          <h6 className="mt-3">ส่วนลด %</h6>
                          <h6 className="mt-3">ส่วนลด</h6>
                          <h6 className="mt-3">มูลค่าสินค้า</h6>
                          <h6>Vat {vat} %</h6>
                          <h6>รวมทั้งหมด</h6>
                        </td>
                        <td>
                          <h6 style={{ textAlign: "right" }}>
                            <Form.Control
                              type="number"
                              min={0}
                              size="sm"
                              style={{ textAlign: "right" }}
                              value={freight}
                              onChange={(e) =>
                                setFreight(e.currentTarget.value)
                              }
                            />
                          </h6>
                          <h6 style={{ textAlign: "right" }}>
                            <Form.Control
                              type="number"
                              min={0}
                              size="sm"
                              style={{ textAlign: "right" }}
                              value={discountPercent}
                              onChange={(e) => {
                                setDiscountPercent(e.currentTarget.value);
                                if (e.currentTarget.value > 0) {
                                  // + Number(freight)
                                  setDiscount(
                                    (
                                      Number(total) *
                                      Number(e.currentTarget.value / 100)
                                    ).toFixed(2)
                                  );
                                } else {
                                  setDiscount(0);
                                }
                                if (e.currentTarget.value > 100) {
                                  setDiscountPercent(100);
                                }
                              }}
                            />
                          </h6>
                          <h6 style={{ textAlign: "right" }}>
                            <Form.Control
                              type="number"
                              min={0}
                              size="sm"
                              style={{ textAlign: "right" }}
                              value={discount}
                              onChange={(e) => {
                                setDiscount(e.currentTarget.value);
                                if (e.currentTarget.value > 0) {
                                  setDiscountPercent(0);
                                }
                              }}
                            />
                          </h6>
                          <h6 style={{ textAlign: "right" }}>
                            {setTHB(totalAll() * (100 / 107))}
                          </h6>
                          <h6 style={{ textAlign: "right" }}>
                            {setTHB(totalAll() * (7 / 107))}
                          </h6>
                          <h6 style={{ textAlign: "right" }}>
                            {setTHB(totalAll())}
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {lists.length >= 1 && (
                  <Row>
                    <Col sm={10}>
                      {/* <Form.Label>หมายเหตุ</Form.Label> */}
                      <Form.Control
                        placeholder="หมายเหตุ"
                        style={{ color: "blue" }}
                        type="text"
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <button
                        className="btn btn-success"
                        style={{ float: "right" }}
                        onClick={() => {
                          saveQT();
                        }}
                      >
                        บันทึก <BsSaveFill />
                      </button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={productShow}
        onHide={() => setProductShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <InputGroup>
              <FormControl
                placeholder="Search..."
                value={p_name}
                ref={searchInput}
                onChange={productSearch}
                // onChange = {(event) => this.setCName({email: event.target.value })}
              />
              <InputGroup.Text id="basic-addon2">
                <BsSearch />
              </InputGroup.Text>
            </InputGroup>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {products.length !== 0 &&
              products.map((p, index) => {
                return (
                  <Col md={4} key={p._id}>
                    <Card className="h-100">
                      <Card.Header>{p.name}{p.c_name && (<span style={{ color: "blue" }}>({p.c_price}/{p.c_unit})</span>)}</Card.Header>
                      <Card.Body>
                        <Image src={p.picture} thumbnail alt={p.detail} />
                      </Card.Body>
                      <Card.Footer className="text-muted">
                      {p.c_name && (<p style={{ color: "red" }}>({p.c_name})</p>)}
                        <p>เลือกหน่วย</p>
                        <Button
                          className="btn"
                          variant="outline-primary"
                          onClick={() => addListsQT(p, "unit_m")}
                        >
                          {p.unit_m}
                        </Button>
                        {p.unit_m !== p.unit_n && (
                          <Button
                            className="btn ml-4"
                            variant="outline-info"
                            onClick={() => addListsQT(p, "unit_n")}
                          >
                            {p.unit_n}
                          </Button>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateInvoice;
