import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
// import typeCategories from "./typeCategories.json";

const schema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อ"),
  type: yup.object().nullable().required("กรุณาเลือกประเภท"),
});

const CreateCategory = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [typeCategory, setType] = React.useState([]);
  const [typeOptions, setTypeOptions] = React.useState([]);

  const _token = JSON.parse(localStorage.getItem("token"));

  // Seting Api (NO getdata)
  const getData = React.useCallback(async () => {
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Products`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting;
    // console.log(resp_setting.data.setting);
    setTypeOptions(
      setting_name.data.Categories.map((anObjectMapped, index) => {
        return { value: anObjectMapped.value, label: anObjectMapped.label };
      })
    );
  }, [_token.access_token]);

  React.useEffect(() => {
    // console.log("use effect");
    getData();
  }, [getData]);

  // END Seting Api (NO getdata)

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}category`;
      const resp = await axios.post(
        apiUrl,
        {
          name: data.name,
          type: data.type.value,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // alert(resp.data.message);

      addToast(resp.data.message, { appearance: "success" });
      history.replace("/category");
    } catch (error) {
      // console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  const typeChange = (change) => {
    setValue("type", change, {
      //   shouldDirty: true,
    });
    setType(change);
  };

  // const typeOptions = typeCategories.map((anObjectMapped, index) => {
  //   return { value: anObjectMapped.value, label: anObjectMapped.label };
  // });

  return (
    <Container className="mt-4">
      <h2>เพิ่มหมวดหมู่สินค้า</h2>
      <Row className="mt-3">
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="name">
              <Form.Label>ชื่อหมวดหมู่สินค้า</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Controller
              name="type"
              control={control}
              defaultValue={[]}
              render={() => (
                <Select
                  placeholder="ชนิดสินค้า.."
                  className="mb-2"
                  options={typeOptions}
                  value={typeCategory}
                  onChange={typeChange}
                />
              )}
            />
            {errors?.type && (
              <p style={{ color: "Crimson", fontSize: "80%" }}>
                {errors.type.message}
              </p>
            )}

            <br />
            <Button variant="primary" type="submit">
              เพิ่มหมวดหมู่
            </Button>

            <Button
            className="ml-3"
              variant="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateCategory;
