import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";

const schema = yup.object().shape({
    new_code: yup.string().required("กรุณากรอก new_code (อย.)"),
    name: yup.object().nullable().required("กรุณากรอกชื่อ"),
    detail: yup.string().required("กรุณากรอกข้อมูลรายละเอียด"),
});

const EditVendor = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();

  const _token = JSON.parse(localStorage.getItem("token"));

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [Name, setName] = React.useState([]);
  const [unitOptions, setUnitOptions] = React.useState([]);

  const getData = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}unit/${id}`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });
    // console.log(resp.data);

    //setValue
    setValue("new_code", resp.data.data.new_code);
    setValue("detail", resp.data.data.detail);
    
    setName(resp.data.data.name);
    setValue("name", { value: resp.data.data.name });

    // Seting Api
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Products`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting.data;
    // console.log(resp_setting.data.setting);
    setUnitOptions(
      setting_name.Units.map((anObjectMapped, index) => {
        return { value: anObjectMapped.value, label: anObjectMapped.label };
      })
    );
    // END Seting Api

  }, [_token.access_token, id, setValue]);

  React.useEffect(() => {
    // console.log("use effect");
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}unit`;
      const resp = await axios.put(
        apiUrl,
        {
          id: id,
          new_code: data.new_code,
          name: data.name.value,
          detail: data.detail,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // console.log(resp.data);

      addToast(resp.data.message, { appearance: "success" });
      history.replace("/unit");
    } catch (error) {
      // console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  return (
    <Container className="mt-4">
      <h2>เพิ่มหน่วยสินค้า</h2>
      <Row className="mt-3">
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Col sm={4}>
                <Form.Label>ชื่อหน่วย</Form.Label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="หน่วยสินค้า.."
                      className="mb-2"
                      options={unitOptions}
                      value={unitOptions.filter(
                        (option) => option.value === Name
                      )}
                      onChange={(e) => {
                        // console.log(e);
                        setName(e.value);                        
                        setValue("name", e);
                        // console.log(Country);
                      }}
                    />
                  )}
                />
                {errors?.name && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.name.message}
                  </p>
                )}
              </Col>
              <Col sm={8}>
                <Form.Group controlId="new_code">
                  <Form.Label>New_Code (อย.)</Form.Label>
                  <Form.Control
                    type="text"
                    name="new_code"
                    autoComplete="off"
                    defaultValue="-"
                    ref={register}
                    className={`form-control ${
                      errors.new_code ? "is-invalid" : ""
                    }`}
                  />
                  {errors.new_code && (
                    <Form.Control.Feedback type="invalid">
                      {errors.new_code.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Group controlId="detail">
              <Form.Label>รายละเอียด</Form.Label>
              <Form.Control
                type="text"
                name="detail"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.detail ? "is-invalid" : ""}`}
              />
              {errors.detail && (
                <Form.Control.Feedback type="invalid">
                  {errors.detail.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <br />
            <Button variant="primary" type="submit">
              แก้ไขหน่วย
            </Button>

            <Button
              className="ml-3"
              variant="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default EditVendor;
