import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const schema = yup.object().shape({
  titlename: yup.string().required("กรุณากรอกคำนำหน้า"),
  name: yup.string().required("กรุณากรอกชื่อ"),
  lastname: yup.string().required("กรุณากรอกนามสกุล"),
  email: yup.string().required("กรุณากรอก email").email('รูปแบบอีเมล์ไม่ถูกต้อง'),
  password: yup.string().required("กรุณากรอก password").min(3, 'password ต้อง 3 ตัวขึ้นไป'),
});

const RegisterPage = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
        // console.log(data);
        const apiUrl = `${process.env.REACT_APP_API_URL}user/register`;
        const resp = await axios.post(apiUrl, {
          titlename: data.titlename,
          name: data.name,
          lastname: data.lastname,
          email: data.email,
          password: data.password,
        });
        // alert(resp.data.message);
        
        addToast(resp.data.message, { appearance: 'success'});
        history.replace("/login");
        
    } catch (error) {
        addToast(error.response.data.errors.email[0], { appearance: 'error'});
    }
  };

  return (
    <Container className="mt-4">
      <h2>เพิ่มข้อมูล</h2>
      <Row>
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="titlename">
              <Form.Label>คำนำหน้า</Form.Label>
              <Form.Control
                type="text"
                name="titlename"
                ref={register}
                className={`form-control ${errors.titlename ? "is-invalid" : ""}`}
              />
              {errors.titlename && (
                <Form.Control.Feedback type="invalid">
                  {errors.titlename.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Label>ชื่อ</Form.Label>
              <Form.Control
                type="text"
                name="name"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="lastname">
              <Form.Label>นามสกุล</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                ref={register}
                className={`form-control ${errors.lastname ? "is-invalid" : ""}`}
              />
              {errors.lastname && (
                <Form.Control.Feedback type="invalid">
                  {errors.lastname.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.password ? "is-invalid" : ""}`}
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Button variant="primary" type="submit">
              สมัครสมาชิก
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPage;
