import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import sarabun from "../../resources/fonts/THSarabunNew.ttf";
import sarabunB from "../../resources/fonts/THSarabunNewBold.ttf";

import {
  PDFViewer,
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  Svg,
  Line,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 0,
    fontFamily: "Sarabun",
  },
  titleH1: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 5,
    fontFamily: "SarabunB",
  },
  titleH2: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 5,
  },
  text: {
    marginLeft: 10,
    fontSize: 18,
  },
  text2: {
    marginLeft: 10,
    fontSize: 16,
  },
});

// Font.register({
//   family: "Sarabun",
//   fonts: [{ src: "./fonts/THSarabunNew.ttf" }],
// });

Font.register({
  family: "Sarabun",
  format: "truetype",
  src: sarabun,
});

Font.register({
  family: "SarabunB",
  format: "truetype",
  src: sarabunB,
});

const PdfAddress = () => {
  const { id } = useParams();
  const [isOnPdfView, setIsOnPdfView] = React.useState(false);

  const [customerData, setCustomerData] = React.useState([]);

  // getData
  const getData = React.useCallback(async () => {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}customer/${id}`
    );
    // console.log(resp.data.data);
    setCustomerData(resp.data.data);
  }, [id]);

  React.useEffect(() => {
    setTimeout(() => {
      getData();
      setIsOnPdfView(true);
    }, 1000);
  }, [getData]);

  return (
    <>
      {isOnPdfView && (
        <PDFViewer className="container-fluid mt-3" height={600}>
          <Document>
            <Page size={["4in", "6in"]} style={styles.body}>
              <Text style={styles.titleH1}>
                บริษัท ริชเอ็นด์เลส เมดิคอล กรุ๊ป จํากัด
              </Text>
              <Text style={styles.titleH2}>
                345/299 หมู่บ้านสายฝน 1 ต.สำราญ อ.เมือง จ.ขอนแก่น 40000 {"\n"}
                โทร.043-424782 แผนกลูกค้าสัมพันธ์ 092-2237453 {"\n"}
                e-mail:info@richendless.co.th, www.richendless.co.th {"\n"}
              </Text>
              <Svg height="10" width="500">
                <Line
                  x1="0"
                  y1="0"
                  x2="500"
                  y2="0"
                  strokeWidth={1}
                  stroke="black"
                />
              </Svg>
              <Text style={styles.text}>ผู้รับ: {customerData.name}</Text>
              <Text style={styles.text2}>
                {customerData.name} {"\n"}
                {customerData.address}
                {customerData.province !== "กรุงเทพมหานคร" ? ` ต.` : `แขวง`}
                {customerData.sub_district} {"\n"}
                {customerData.province !== "กรุงเทพมหานคร"
                  ? ` อ.${customerData.district} จ.${customerData.province} `
                  : `เขต${customerData.district} ${customerData.province} `}
                {customerData.zipcode} {"\n"}
              </Text>
              <Text style={styles.text}>โทรศัพท์: {customerData.tel}</Text>
              <Text style={styles.titleH1}>ห้องการเงิน ({customerData.rec_tel_f === '-' ? customerData.tel : customerData.rec_tel_f})</Text>
              <Svg height="100" width="500">
                <Line
                  x1="0"
                  y1="0"
                  x2="500"
                  y2="0"
                  strokeWidth={1}
                  stroke="black"
                />
              </Svg>
              <Text style={styles.titleH1}>
                จำนวนกล่อง.............................................
              </Text>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default PdfAddress;
