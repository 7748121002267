import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { NavLink, useHistory } from "react-router-dom";

// import { UserStoreContext } from "../context/UserContext";

import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../redux/action/authAction";

const NavBar = () => {
  const history = useHistory();
  // const [profile, setProfile] = React.useState(null);
  // const userStore = React.useContext(UserStoreContext)

  //Redux
  const profileRedux = useSelector((state) => state.authReducer.profile);
  // const total = useSelector((state) => state.cartReducer.total);

  const dispatch = useDispatch();

  // const getProfile = () => {
  //   const profileValue = JSON.parse(localStorage.getItem("profile"));
  //   if (profileValue) {
  //     setProfile(profileValue);
  //   }
  // };

  // React.useEffect(() => {
  //   console.log("use effect navber");
  //   getProfile();
  // }, []);

  //context
  //   const getProfile = () => {
  //   const profileValue = JSON.parse(localStorage.getItem("profile"));
  //   if (profileValue) {
  //     userStore.updateProfile(profileValue);
  //   }
  // };

  // React.useEffect(() => {
  //   // console.log("use effect navber");
  //   getProfile();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps,
  // }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    history.replace("/");
    // history.go(0)
    // userStore.updateProfile(null)
    dispatch(updateProfile(null));
  };

  //redux
  const getProfile = () => {
    const profileValue = JSON.parse(localStorage.getItem("profile"));
    const profileToken = JSON.parse(localStorage.getItem("token"));
    if (profileToken) {
      if (profileToken.expires_in * 1000 < Date.now()) {
        // console.log('profileToken');
        localStorage.removeItem("token");
        localStorage.removeItem("profile");
        history.replace("/");
        // history.go(0)
        // userStore.updateProfile(null)
        dispatch(updateProfile(null));
      } else {
        if (profileValue) {
          dispatch(updateProfile(profileValue));
          // console.log(profileToken);
        }
      }
    }
  };

  React.useEffect(() => {
    // console.log("use effect navber");
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  return (
    <>
      <Navbar bg="success" expand="lg" variant="dark">
        <NavLink className="navbar-brand mr-3" to="/" exact>
          <img
            src="/images/richLogo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt=""
          />{" "}
          RERP
        </NavLink>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <NavDropdown
              title="Old"
              // id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/");
                }}
              >
                หน้าหลัก
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/product");
                }}
              >
                สินค้า
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/about");
                }}
              >
                เกี่ยวกับเรา
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/upload");
                }}
              >
                อัพโหลดไฟล์
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/member");
                }}
              >
                Member
              </NavDropdown.Item>

              <NavDropdown
                title="Workshop (Paginaion + CRUD)"
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  onClick={() => {
                    history.replace("/hospital");
                  }}
                >
                  ข้อมูลสถานพยาบาล (Paginaion)
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => {
                    history.replace("/category");
                  }}
                >
                  หมวดหมู่ข่าว (CRUD)
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown> */}
            {profileRedux ? (
              <>
                {["admin", "manager"].includes(profileRedux.role) ? (
                  <>
                    <NavDropdown
                      title="สินค้า"
                      // id="navbarScrollingDropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/category");
                        }}
                      >
                        หมวดหมู่
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/product");
                        }}
                      >
                        สินค้า
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <NavDropdown
              title="งานขาย"
              // id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/quotation");
                }}
              >
                ใบเสนอราคา
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/invoice");
                }}
              >
                ออกบิล
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  history.replace("/customer");
                }}
              >
                ลูกค้า
              </NavDropdown.Item>
            </NavDropdown>

            {profileRedux ? (
              <>
                {["admin", "purchasing"].includes(profileRedux.role) ? (
                  <>
                    <NavDropdown
                      title="ฝ่ายจัดซื้อ"
                      // id="navbarScrollingDropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/purchase/TH");
                        }}
                      >
                        ใบสั่งซื้อ(ในประเทศ)
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/purchase/OT");
                        }}
                      >
                        ใบสั่งซื้อ(ต่างประเทศ)
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/vendor");
                        }}
                      >
                        ผู้จำหน่าย
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

{profileRedux ? (
              <>
                {["admin"].includes(profileRedux.role) ? (
                  <>
                    <NavDropdown
                      title="คลังสินค้า"
                      // id="navbarScrollingDropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/purchase/TH");
                        }}
                      >
                        นำเข้าสินค้า
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/purchase/OT");
                        }}
                      >
                        ส่งสินค้า
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => {
                          history.replace("/vendor");
                        }}
                      >
                        เคลียร์สินค้า
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {/* <NavLink
              className="nav-link"
              to="/cart"
              exact
              activeClassName="active"
            >
              ตะกร้าสินค้า {total}
            </NavLink> */}

            {/* <NavLink
              className="nav-link"
              to="/chart"
              exact
              activeClassName="active"
            >
              รายงาน Charts
            </NavLink> */}
          </Nav>

          {
            // userStore.profile ? (
            //   <>
            //   <span className="nav-bar-text text-white">
            //     {/* ยินดีต้อนรับ {userStore.profile.name} */}
            //     ยินดีต้อนรับ {userStore.profile.name}
            //     </span>
            //   <button className="btn btn-danger ml-2" onClick={logout}>
            //     Log Out
            //   </button>
            //   </>
            // ) : (
            //   <>
            //     <Nav>
            //       <NavLink
            //         className="nav-link"
            //         to="/register"
            //         exact
            //         activeClassName="active"
            //       >
            //         Register
            //       </NavLink>
            //       <NavLink
            //         className="nav-link"
            //         to="/login"
            //         exact
            //         activeClassName="active"
            //       >
            //         Login
            //       </NavLink>
            //     </Nav>
            //   </>
            // )
            profileRedux ? (
              <>
                <span className="nav-bar-text text-white">
                  {/* ยินดีต้อนรับ {userStore.profile.name} */}
                  ยินดีต้อนรับ {profileRedux.name}
                </span>
                <button className="btn btn-danger ml-2" onClick={logout}>
                  Log Out
                </button>
              </>
            ) : (
              <>
                <Nav>
                  <NavLink
                    className="nav-link"
                    to="/register"
                    exact
                    activeClassName="active"
                  >
                    Register
                  </NavLink>
                  <NavLink
                    className="nav-link"
                    to="/login"
                    exact
                    activeClassName="active"
                  >
                    Login
                  </NavLink>
                </Nav>
              </>
            )
          }
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
