import React from "react";

import { getVersion } from "../redux/action/authAction";
import { useDispatch } from "react-redux"; //, useSelector

const Footer = () => {
  const dispatch = useDispatch()
  // const version = useSelector((state) => state.authReducer.version)

  React.useEffect(() => {
    dispatch(getVersion())    
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  },[])

  return (
    <>
    {/* <footer className="fixed-bottom"> */}
      <div className='container mt-3'>
      <p>© Richendless 2019-{new Date().getFullYear()} API version:{`2022v1`}</p>
      </div>
    {/* </footer> */}
    </>
  );
};

export default Footer;
