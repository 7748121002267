import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import sarabun from "../../resources/fonts/THSarabunNew.ttf";
import sarabunB from "../../resources/fonts/THSarabunNewBold.ttf";
import THBText from "thai-baht-text";
import { format } from "date-fns";

import {
  PDFViewer,
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  View,
} from "@react-pdf/renderer";

const PdfAddress = () => {
  const { id } = useParams();
  const { type } = useParams();
  const [isOnPdfView, setIsOnPdfView] = React.useState(false);

  const [quotationData, setQuotationData] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState([]);

  const [dateTH, setDateTH] = React.useState("");

  const _token = JSON.parse(localStorage.getItem("token"));

  // getData
  const getData = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}invoice/${id}`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });
    // console.log(resp.data.data);
    setQuotationData(resp.data.data.quotation);
    setInvoiceData(resp.data.data.invoice);
    const tempDate = new Date(resp.data.data.invoice.dateIV);
    // console.log(tempDate.getFullYear());
    setDateTH(tempDate.setFullYear(tempDate.getFullYear() + 543));
  }, [id, _token.access_token]);

  React.useEffect(() => {
    getData();
    setTimeout(() => {
      setIsOnPdfView(true);
    }, 2000);
  }, [getData]);

  const setTHB = (b) => {
    let bath = Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
    }).format(b);
    return bath.substring(1);
    // return bath;
  };

  return (
    <>
      {isOnPdfView && (
        <PDFViewer className="container-fluid mt-3" height={600}>
          <Document>
            <Page
              size={["8.5in", "10.5in"]}
              style={[styles.body, { marginTop: "1.78in" }]}
            >
              { type === "bank" &&
                <View
                  style={{
                    paddingTop: "4.75in",
                    paddingLeft: "1.5in",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <Text>กรุณาชําระเงินได้ที่</Text>
                  <Text>ธนาคารกสิกรไทย สาขาถนนหน้าเมือง ขอนแก่น</Text>
                  <Text>ชื่อบัญชี:บจก.ริชเอ็นด์เลส เมดิคอล กรุ๊ป</Text>
                  <Text>เลขที่บัญชี 068-8-93064-2</Text>
                </View>
              }

              <View style={[styles.row, { height: "1.1in" }]}>
                <View
                  style={[styles.left, { paddingLeft: "1in", width: "70%" }]}
                >
                  <Text>{quotationData.customer.name}</Text>
                  <Text style={[{ paddingTop: "0.1in" }]}>
                    {quotationData.customer.full_addr1}
                    {"\n"}
                    {quotationData.customer.full_addr2}
                  </Text>
                  <Text style={[styles.text14, { paddingTop: "0.2in" }]}>
                    {quotationData.customer.taxId !== "-" &&
                      `เลขประจำตัวผู้เสียภาษี ${quotationData.customer.taxId}`}{" "}
                    {"\n"}
                    {quotationData.customer.tel}
                  </Text>
                </View>
                <View style={styles.right}>
                  <Text>{invoiceData.iv}</Text>
                  <Text style={[styles.text14, { paddingTop: "0.25in" }]}>
                    {type === "nodate"
                      ? " "
                      : format(new Date(dateTH), "dd/MM/yyyy")}
                    {/* .add(543, 'year') */}
                  </Text>
                  <Text style={[styles.text14, { paddingTop: "0.25in" }]}>
                    {quotationData.customer.code}
                  </Text>
                </View>
              </View>
              {/* <Text style={styles.text14}>{"\n"}</Text>
              <Text style={[styles.text14, { paddingBottom: 5 }]}>{"\n"}</Text> */}

              <View style={[styles.row, { marginTop: 45, height: "0.8in" }]}>
                <View style={[{ marginLeft: "40" }]}>
                  <View style={[styles.row, { paddingBottom: 5 }]}>
                    <View style={[{ width: "23%" }]}>
                      <Text style={styles.text14B}>
                        {invoiceData.po !== "-" ? invoiceData.po : ""}
                      </Text>
                      <Text style={styles.text14B}></Text>
                    </View>
                    <View style={[{ width: "20%" }]}>
                      <Text style={styles.text14B}>
                        {invoiceData.c_pay} วัน
                      </Text>
                      <Text style={styles.text14B}></Text>
                    </View>
                    <View style={[{ width: "17%" }]}>
                      <Text style={styles.text14B}>
                      </Text>
                      <Text style={styles.text14B}></Text>
                    </View>
                    <View style={[{ width: "15%" }]}>
                      <Text style={styles.text14B}>{invoiceData.shipment}</Text>
                      <Text style={styles.text14B}></Text>
                    </View>
                    <View style={[{ width: "25%" }]}>
                      <Text style={[styles.text14, { paddingLeft: 16 }]}>
                        {quotationData.sale.fullname.substring(0, 20)}
                      </Text>
                      {/* <Text style={[styles.text14, { paddingLeft: 10}]}>{"นางสาวกันต์ลลิตา กันตาพิพัฒน์กุล".substring(0, 20)}</Text> */}
                      <Text style={styles.text14B}></Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={[
                  { height: "3.25in", paddingRight: 32, paddingLeft: 0 },
                ]}
              >
                {/* List */}
                {quotationData.lists.map((l, index) => {
                  return (
                    <View key={l._id} style={styles.row}>
                      <View style={[stylesTb.tableCol_P, { width: "10.2%" }]}>
                        <Text
                          style={[stylesTb.tableCellR, { paddingRight: 15 }]}
                          // style={[{ width: "10%", paddingRight: 15,textAlign:'right'}]}
                        >
                          {index + 1}
                        </Text>
                      </View>
                      <View style={[stylesTb.tableCol_P, { width: "40.5%" }]}>
                        {/* <Text  style={stylesTb.tableCellD}>{l.name}
                    {l.detail !== "-" && <Text style={[{ marginLeft: 20 }]}>{"\n"}{l.detail.replace("^", "\n")}</Text>}
                  </Text>                     */}
                        <Text style={[stylesTb.tableCell]}>{l.name}</Text>
                        {l.detail !== "-" && (
                          <Text
                            style={[stylesTb.tableCell, { paddingLeft: 15 }]}
                          >
                            {l.detail.replace("!", "\n")}
                          </Text>
                        )}
                      </View>
                      <View style={[stylesTb.tableCol_P, { width: "9.2%" }]}>
                        <Text style={[stylesTb.tableCellR]}>{l.unit}</Text>
                      </View>
                      <View style={[stylesTb.tableCol_P, { width: "9.1%" }]}>
                        <Text style={[stylesTb.tableCellR]}>{l.qty}</Text>
                      </View>
                      <View style={[stylesTb.tableCol_P, { width: "12.9%" }]}>
                        <Text style={[stylesTb.tableCellR]}>
                          {setTHB(l.price)}
                        </Text>
                      </View>
                      <View style={[stylesTb.tableCol_P, { width: "6.5%" }]}>
                        <Text style={[stylesTb.tableCellR]}>
                          {l.discount !== 0 && setTHB(l.discount)}
                        </Text>
                      </View>
                      <View style={[stylesTb.tableCol_P, { width: "11.6%" }]}>
                        <Text
                          style={[stylesTb.tableCellR, { paddingRight: 10 }]}
                        >
                          {setTHB(l.price * l.qty)}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              {/* footer */}
              <View style={[styles.row, { paddingRight: 32, paddingLeft: 10 }]}>
                <View style={[stylesTb.tableCol2, { paddingLeft: 20 }]}>
                  <View style={[styles.row, { paddingBottom: 5 }]}>
                    <View style={[{ width: "80%", paddingLeft: 20 }]}>
                      <Text style={stylesTb.tableCell}>
                        {"\n"}
                        {"\n"}
                        {"\n"}({THBText(quotationData.total)})
                      </Text>
                    </View>
                    <View style={[{ width: "20%", margin: 5 }]}>
                      <Text style={styles.text12}>
                        {"\n"}
                        {"\n"}
                        {quotationData.vat}% {"\n"}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={stylesTb.tableCol}>
                  <Text style={[stylesTb.tableCellR, { paddingRight: 10 }]}>
                    {"\n"}
                    {setTHB(quotationData.total * (100 / 107)) + "\n"}
                    {quotationData.discount > 0 &&
                      setTHB(quotationData.discount) + "\n"}
                    {setTHB(quotationData.total * (7 / 107)) + "\n"}
                    {setTHB(quotationData.total)}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default PdfAddress;

const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: "0in",
    fontFamily: "Sarabun",
    fontSize: 15,
  },
  row: {
    // flex: 1,
    flexDirection: "row",
    // flexGrow: 1,
  },
  left: {
    padding: 5,
    // width: '33%',//<- working alternative
    flexGrow: 0,
    flexShrink: 1,
    // flexBasis: 200,
  },
  right: {
    padding: 5,
    flexGrow: 1,
    // width: '66%', //<- working alternative
    flexShrink: 1,
    // flexGrow: 2,
  },
});

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 40;
// const COL_P_WIDTH = 10;
// const COLN_WIDTH = 10;
// const COL2_WIDTH = COL1_WIDTH + COLN_WIDTH * 5;
const COLN_WIDTH = (100 - COL1_WIDTH) / 6;
const COL2_WIDTH = COL1_WIDTH + COLN_WIDTH * 5;
const stylesTb = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: COL2_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol_P: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 2,
    textAlign: "center",
    fontSize: 13.5,
  },
  tableCellR: {
    textAlign: "right",
    paddingRight: 2,
    margin: 2,
    fontSize: 14,
  },
  tableCell: {
    margin: 2,
    fontSize: 14,
  },
});

Font.register({
  family: "SarabunB",
  format: "truetype",
  src: sarabun,
});

Font.register({
  family: "SarabunB",
  format: "truetype",
  src: sarabunB,
});
