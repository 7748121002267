import React from "react";
import { BsFillLightningFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const HomePage = () => {
  // const { isLoading, error, data, isFetching } = useQuery("getData", () =>
  //   fetch("https://api.codingthailand.com/api/news?page=1&per_page=3").then(
  //     (res) => res.json()
  //   )
  // );//Redux
  const profileRedux = useSelector((state) => state.authReducer.profile);

  const query = useQuery("getData", () => {
    const controller = new AbortController();
    const signal = controller.signal;

    const promise = fetch(
      "https://api.codingthailand.com/api/news?page=1&per_page=3",
      {
        method: "get",
        signal: signal,
      }
    ).then((res) => res.json());

    //cancle request
    promise.cancel = () => controller.abort();

    return promise;
  });

  const { isLoading, error } = query;
  // data, isFetching
  if (isLoading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        <p>เกิดข้อผิดพลาดจาก Server กรุณาลองใหม่</p>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  }

  return (
    <>
      <main role="main">
        <div className="jumbotron">
          <div className="container">
            <h1 className="display-3">
              <b>Richendless Medical Group</b>
            </h1>
            {/* <p>
              เว็บนี้พัฒนาด้วย React{" "}
              {<BsFillLightningFill color="red" size="1em" />}
            </p> */}
            <p>
              บริษัท ริชเอ็นด์เลส เมดิคอล กรุ๊ป จำกัด{" "}
              {<BsFillLightningFill color="red" size="1em" />}
            </p>

            <p>
              <Link
                to="/invoice"
                className="btn btn-success btn-lg"
                role="button"
              >
                ออกบิล »
              </Link>
              <Link
                to="/quotation"
                className="btn btn-warning btn-lg ml-3"
                role="button"
              >
                ใบเสนอราคา »
              </Link>
              {profileRedux ? (
                <>
                  {["admin", "Purchasing"].includes(profileRedux.role) ? (
                    <>
                      <Link
                        to="/purchase/TH"
                        className="btn btn-primary btn-lg ml-3"
                        role="button"
                      >
                        ใบสั่งซื้อ »
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
        <div className="container">
          {/* Example row of columns */}
          {/* <div className="row">
            <div className="mx-auto">
              {isFetching ? "กำลังอัพเดท..." : null}
            </div>
            {data.data.map((news, index) => {
              return (
                <div className="col-md-4" key={news.id}>
                  <h2>{news.topic}</h2>
                  <p>{news.detail}</p>
                  <p>หมวดหมู่: {news.name}</p>
                </div>
              );
            })}
          </div> */}
          <hr />
        </div>{" "}
        {/* /container */}
      </main>
    </>
  );
};

export default HomePage;
