import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
// import { UserStoreContext } from '../context/UserContext'

import { useDispatch } from "react-redux";
import { updateProfile } from "../redux/action/authAction";

const schema = yup.object().shape({
  email: yup.string().required("กรุณากรอก email").email('รูปแบบอีเมล์ไม่ถูกต้อง'),
  password: yup.string().required("กรุณากรอก password").min(3, 'password ต้อง 3 ตัวขึ้นไป'),
});

const LoginPage = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  // const UserStore = React.useContext(UserStoreContext)

  //call redex action
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
        // console.log(data);
        // const apiUrl = "http://localhost:3500/user/login";
        const apiUrl = `${process.env.REACT_APP_API_URL}user/login`;
        const resp = await axios.post(apiUrl, {
          email: data.email,
          password: data.password,
        });
        // console.log(resp);

        // console.log(resp.data)
        localStorage.setItem('token', JSON.stringify(resp.data))

        //get profile
        // const urlProfile = "http://localhost:3500/user/me";
        const urlProfile = `${process.env.REACT_APP_API_URL}user/me`;
        const respProfile = await axios.get(urlProfile, {
            headers: {
                Authorization: 'Bearer '+ resp.data.access_token
            }
        })
        // console.log(respProfile.data.data.user)
        localStorage.setItem('profile', JSON.stringify(respProfile.data.data.user))
        
        addToast('เข้าระบบเรียบร้อยแล้ว', { appearance: 'success'});

        // history.replace('/');
        // history.go(0)
        //update profile by context
        const profileValue = JSON.parse(localStorage.getItem('profile'))
        // UserStore.updateProfile(profileValue) //context
        //call action
        dispatch(updateProfile(profileValue))
        history.replace('/')
        
    } catch (error) {
      // console.log(error.response.data.error.message)
        addToast(error.response.data.error.message, { appearance: 'error'});
    }
  };

  return (
    <Container className="mt-4">
      <h2>เข้าสู่ระบบ</h2>
      <Row>
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.password ? "is-invalid" : ""}`}
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
