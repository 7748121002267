import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
// import typeCategories from "./typeCategories.json";

const schema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อ"),
  type: yup.object().nullable().required("กรุณาเลือกประเภท"),
});

const EditCategory = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();

  const { register, handleSubmit, control, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const _token = JSON.parse(localStorage.getItem("token"));

  const getData = React.useCallback(async () => {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}category/${id}`
    );
    console.log(resp.data);
    setValue("name", resp.data.data.name);

    setValue("type", { value: resp.data.data.type });
    setType(resp.data.data.type);

    // Seting Api
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Products`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting.data;
    console.log(resp_setting.data.setting.data.Country);
    
    setTypeOptions(
        setting_name.Categories.map((anObjectMapped, index) => {
          return { value: anObjectMapped.value, label: anObjectMapped.label };
        })
    );
    // END Seting Api

    // console.log(typeCategory)
  }, [_token.access_token,id, setValue]);

  React.useEffect(() => {
    // console.log("use effect");

    getData();
  }, [getData]);

  const [type, setType] = React.useState([]);
  const [typeOptions, setTypeOptions] = React.useState([]);

  const onSubmit = async (data) => {
    // console.log(data);

    try {
      // console.log(data);
      const apiUrl = `${process.env.REACT_APP_API_URL}category`;
      const resp = await axios.put(
        apiUrl,
        {
          id: id,
          name: data.name,
          type: data.type.value,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // alert(resp.data.message);

      addToast(resp.data.message, { appearance: "success" });
      history.replace("/category");
    } catch (error) {
      console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  return (
    <Container className="mt-4">
      <h2>แก้ไขหมวดหมู่สินค้า</h2>
      <Row className="mt-3">
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="name">
              <Form.Label>ชื่อหมวดหมู่สินค้า</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Controller
              name="type"
              control={control}
              defaultValue={[]}
              // defaultValue={typeOptions.find( ({ value }) => value === typeCategory )}
              render={() => (
                <Select
                  placeholder="ชนิดสินค้า.."
                  className="mb-2"
                  options={typeOptions}
                  value={typeOptions.filter((option) => option.value === type)}
                  onChange={(e) => {
                    setValue("type", e);
                    setType(e.value);
                  }}
                />
              )}
            />
            {errors?.type && (
              <p style={{ color: "Crimson", fontSize: "80%" }}>
                {errors.type.message}
              </p>
            )}

            <br />
            <Button variant="warning" type="submit">
              แก้ไข
            </Button>

            <Button
              className="ml-3"
              variant="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default EditCategory;
