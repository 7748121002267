import React from "react";
import { Route, Redirect } from 'react-router-dom'

export default function PrivateRoute({ children, ...rest }) {
    let isAuth = false;

    const token = JSON.parse(localStorage.getItem("token"));

    if(token){
        isAuth = true;
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
        isAuth ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }