import React from "react";
import { Spinner, Table, Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import axios from "axios";
import { BsPencil } from "react-icons/bs";

import { useHistory } from "react-router";

const pageSize = 15;

const IndexCategory = () => {
  const history = useHistory();
  const _token = JSON.parse(localStorage.getItem("token"));

  const [category, setCategory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const cancelToken = React.useRef(null);

  //pagination
  const [page, setPage] = React.useState();
  const [total, setTotal] = React.useState(0);

  // const _token = JSON.parse(localStorage.getItem("token"));

  const getData = async (page) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}category?page=${page}&size=${pageSize}`;
      const resp = await axios.get(apiUrl, {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
        cancelToken: cancelToken.current.token,
      });
      resp.data.data !== [] && (setCategory(resp.data.data));
      setTotal(resp.data.meta.pagination.total);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();

    getData(page);

    // console.log("use effect");

    return () => {
      cancelToken.current.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        <p>เกิดข้อผิดพลาดจาก Server กรุณาลองใหม่</p>
        <p>{error.response.data.message}</p>
      </div>
    );
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2>หมวดหมู่สินค้า</h2>
            <div className="row">
              <div className="col-sm-4">
                <button
                  className="btn btn-primary mb-3"
                  onClick={() => {
                    history.push("/category/create");
                  }}
                >
                  เพิ่มหมวดหมู่
                </button>
              </div>
              <div className="col-sm-8"></div>
            </div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>หมวดหมู่สินค้า</th>
                  <th>ประเภท</th>
                  <th>เครื่องมือ</th>
                </tr>
              </thead>
              <tbody>
                {category.map((c, index) => {
                  return (
                    <tr key={c._id}>
                      <td>{c.name}</td>
                      <td>{c.type}</td>
                      <td>
                        <Button
                          className="ml-2"
                          variant="info"
                          size="sm"
                          onClick={() =>
                            history.push(`/category/edit/${c._id}`)
                          }
                        >
                          <BsPencil />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            
            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              // prevPageText="ก่อนหน้า"
              firstPageText="หน้าแรก"
              lastPageText="หน้าสุดท้าย"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexCategory;
