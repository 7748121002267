import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import sarabun from "../../resources/fonts/THSarabunNew.ttf";
import sarabunB from "../../resources/fonts/THSarabunNewBold.ttf";
import THBText from "thai-baht-text";

import {
  PDFViewer,
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  Svg,
  Line,
  View,
  Image,
} from "@react-pdf/renderer";

const PdfAddress = () => {
  const { id } = useParams();
  const [isOnPdfView, setIsOnPdfView] = React.useState(false);

  const [quotationData, setQuotationData] = React.useState([]);

  const _token = JSON.parse(localStorage.getItem("token"));

  // getData
  const getData = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}quotation/${id}`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });
    // console.log(resp.data.data);
    setQuotationData(resp.data.data);
  }, [id, _token.access_token]);

  React.useEffect(() => {
    setTimeout(() => {
      setIsOnPdfView(true);
    }, 2000);
    getData();
  }, [getData]);

  const setTHB = (b) => {
    let bath = Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
    }).format(b);

    return bath.substring(1);
  };

  return (
    <>
      {isOnPdfView && (
        <PDFViewer className="container-fluid mt-3" height={600}>
          <Document>
            <Page size={"A4"} style={styles.body}>
              <View style={[styles.row, { height: "1.1in" }]}>
                <View style={styles.left}>
                  {/* <Image
                    style={styles.image}
                    src="http://richendless.co.th/img/V4.png"
                  /> */}
                  <Image
                    style={[{ maxWidth: 70 }]}
                    src="/images/richLogo.png"
                  />
                  {/* <Image style={styles.image} src="../../resources/picture/richLogo.png" /> */}
                </View>
                <View style={styles.right}>
                  <Text style={styles.text14B}>
                    บริษัท ริชเอ็นด์เลส เมดิคอล กรุ๊ป จํากัด
                  </Text>
                  <Text style={styles.text14}>
                    345/299 หมู่บ้านสายฝน 1 ตำบลสำราญ อำเภอเมือง จังหวัดขอนแก่น
                    40000 {"\n"}
                    โทร.043-424782 แผนกลูกค้าสัมพันธ์ 092-2237453
                    e-mail:info@richendless.co.th, www.richendless.co.th {"\n"}
                    เลขที่ประจำตัวผู้เสียภาษีอากร 0405563001306 {"\n"}
                  </Text>
                </View>
              </View>
              <Svg width="520" style={[{ height: "10" }]}>
                <Line
                  x1="0"
                  y1="0"
                  x2="520"
                  y2="0"
                  strokeWidth={1}
                  stroke="black"
                />
              </Svg>
              <Text style={styles.text14}>เลขที่ {quotationData.qt}</Text>

              <Text style={styles.titleH}>
                ใบเสนอราคา
              </Text>

              <View style={[styles.row, { paddingBottom: 20 }]}>
                <View style={[{ marginRight: "15" }]}>
                  <Text style={styles.text14B}>เรื่อง</Text>
                  <Text style={styles.text14B}>เรียน</Text>
                </View>
                <View >
                  <Text style={styles.text14}>ขอเสนอราคาสินค้า</Text>
                  <Text style={styles.text14}>
                    {quotationData.customer.owner}
                  </Text>
                </View>
              </View>

              <Text style={[styles.text14, { paddingLeft: 40 }]}>
                ด้วย บริษัท ริชเอ็นด์เลส เมดิคอล กรุ๊ป จำกัด
                ซึ่งเป็นบริษัทนำเข้า และจัดจำหน่ายเครื่องมือแพทย์
                ที่มีมาตรฐานสากล
              </Text>
              <Text style={[styles.text14, { paddingBottom: 5 }]}>
                มีความยินดีขอเสนอราคาสินค้าเพื่อท่านพิจารณาสั่งซื้อดังนี้
              </Text>

              <View style={stylesTb.table}>
                <View style={stylesTb.tableRow}>
                  <View style={stylesTb.tableColHeader}>
                    <Text style={stylesTb.tableCellHeader}>ลำดับ</Text>
                  </View>
                  <View style={stylesTb.tableCol1Header}>
                    <Text style={stylesTb.tableCellHeader}>รายละเอียด</Text>
                  </View>
                  <View style={stylesTb.tableColHeader}>
                    <Text style={stylesTb.tableCellHeader}>หน่วย</Text>
                  </View>
                  <View style={stylesTb.tableColHeader}>
                    <Text style={stylesTb.tableCellHeader}>จำนวน</Text>
                  </View>
                  <View style={stylesTb.tableColHeader}>
                    <Text style={stylesTb.tableCellHeader}>หน่วยละ</Text>
                  </View>
                  <View style={stylesTb.tableColHeader}>
                    <Text style={stylesTb.tableCellHeader}>ส่วนลด</Text>
                  </View>
                  <View style={stylesTb.tableColHeader}>
                    <Text style={stylesTb.tableCellHeader}>จำนวนเงิน</Text>
                  </View>
                </View>
                {/* List */}

                {quotationData.lists.map((l, index) => {
                  return (
                    <View key={l._id} style={stylesTb.tableRow}>
                      <View style={stylesTb.tableCol}>
                        <Text
                          style={[stylesTb.tableCellR, { paddingRight: 15 }]}
                        >
                          {index + 1}
                        </Text>
                      </View>
                      <View style={stylesTb.tableCol1}>
                        {/* <Text debug style={stylesTb.tableCellD}>{l.name}
                    {l.detail !== "-" && <Text style={[{ marginLeft: 20 }]}>{"\n"}{l.detail.replace("^", "\n")}</Text>}
                  </Text>                     */}
                        <Text                          
                          style={[
                            styles.text12,
                            { marginTop: 5, marginLeft: 5 },
                          ]}
                        >
                          {l.name}
                        </Text>
                        {l.detail !== "-" && (
                          <Text                            
                            style={[styles.text12, { paddingLeft: 15 }]}
                          >
                            {l.detail.replace("!", "\n")}
                          </Text>
                        )}
                      </View>
                      <View style={stylesTb.tableCol}>
                        <Text style={[stylesTb.tableCellR]}>{l.unit}</Text>
                      </View>
                      <View style={stylesTb.tableCol}>
                        <Text style={[stylesTb.tableCellR]}>{l.qty}</Text>
                      </View>
                      <View style={stylesTb.tableCol}>
                        <Text style={[stylesTb.tableCellR]}>
                          {setTHB(l.price)}
                        </Text>
                      </View>
                      <View style={stylesTb.tableCol}>
                        <Text style={[stylesTb.tableCellR]}>
                          {l.discount !== 0 && setTHB(l.discount)}
                        </Text>
                      </View>
                      <View style={stylesTb.tableCol}>
                        <Text style={[stylesTb.tableCellR]}>
                          {setTHB(l.price * l.qty)}
                        </Text>
                      </View>
                    </View>
                  );
                })}

                {/* footer */}
                <View style={stylesTb.tableRow}>
                  <View style={stylesTb.tableCol2}>
                    <View style={[styles.row, { paddingBottom: 5 }]}>
                      <View style={[{ width: "80%", paddingLeft: 20 }]}>
                        <Text style={stylesTb.tableCell}>หมายเหตุ</Text>
                        <Text style={stylesTb.tableCell}>
                          ({THBText(quotationData.total)})
                        </Text>
                      </View>
                      <View                        
                        style={[
                          { textAlign: "right", width: "20%", margin: 5 },
                        ]}
                      >
                        <Text style={styles.text12}>
                          มูลค่าสินค้า {"\n"}
                          {quotationData.discount !== 0 && "ส่วนลด\n"}
                          ภาษีมูลค่าเพิ่ม {quotationData.vat}% {"\n"}
                          รวมเป็นเงินทั้งสิ้น
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={stylesTb.tableCol}>
                    <Text style={[stylesTb.tableCellR]}>
                      {setTHB(quotationData.total * (100 / 107))+"\n"}
                      {quotationData.discount !== 0 &&
                        setTHB(quotationData.discount) + "\n"}
                      {setTHB(quotationData.total * (7 / 107))+"\n"}
                      {setTHB(quotationData.total)}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.row, { paddingBottom: 20 }]}>
                <View style={[{ marginRight: "15" }]}>
                  <Text style={styles.text14B}>เงื่อนไข</Text>
                </View>
                <View >
                  <Text style={styles.text14}>
                    • ราคาที่เสนอรวมภาษีมูลค่าเพิ่ม {quotationData.vat}% แล้ว
                  </Text>
                  <Text style={styles.text14}>
                    • กำหนดยืนราคาภายใน {quotationData.c_price} วัน
                  </Text>
                  <Text style={styles.text14}>
                    • กำหนดส่งของภายใน {quotationData.c_freight} วัน
                  </Text>
                </View>
              </View>

              <Text
                style={[
                  styles.text14,
                  { textAlign: "center", paddingBottom: 20 },
                ]}
              >
                ทั้งนี้ บริษัท ริชเอ็นด์เลสเมดิคอลกรุ๊ป จำกัด
                หวังว่าจะได้รับการพิจารณาสั่งซื้อจากท่าน และขอขอบพระคุณมา ณ
                โอกาสนี้ด้วย
              </Text>
              <Text
                style={[
                  styles.text14,
                  { textAlign: "center", paddingBottom: 25 },
                ]}
              >
                ขอแสดงความนับถือ
              </Text>
              <Text style={[styles.text14, { textAlign: "center" }]}>
                ({quotationData.sale.fullname}) {"\n"}ผู้เสนอราคา
              </Text>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default PdfAddress;

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 0,
    paddingHorizontal: "0.5in",
    fontFamily: "Sarabun",
  },
  row: {
    // flex: 1,
    flexDirection: "row",
    // flexGrow: 1,
  },
  left: {
    padding: 5,
    // width: '33%',//<- working alternative
    flexGrow: 0,
    flexShrink: 1,
    // flexBasis: 200,
  },
  right: {
    padding: 5,
    flexGrow: 1,
    // width: '66%', //<- working alternative
    flexShrink: 1,
    // flexGrow: 2,
  },
  text12: {
    fontSize: 12,
    fontFamily: "Sarabun",
  },
  text14: {
    fontSize: 14,
    fontFamily: "Sarabun",
  },
  text14B: {
    fontSize: 14,
    fontFamily: "SarabunB",
  },
  titleH: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
    fontFamily: "SarabunB",
  },
});

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 40;
const COLN_WIDTH = (100 - COL1_WIDTH) / 6;
const COL2_WIDTH = COL1_WIDTH + COLN_WIDTH * 5;
const stylesTb = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: COL2_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 2,
    textAlign: "center",
    fontSize: 14,
  },
  tableCellR: {
    textAlign: "right",
    paddingRight: 3,
    margin: 5,
    fontSize: 12,
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
});

Font.register({
  family: "Sarabun",
  format: "truetype",
  src: sarabun,
});

Font.register({
  family: "SarabunB",
  format: "truetype",
  src: sarabunB,
});
