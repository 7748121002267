import React from "react";

import { ToastProvider } from "react-toast-notifications";
import { QueryClient, QueryClientProvider } from "react-query";
import UserStoreProvider from "./context/UserContext";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";

//login
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./guard/auth";

//Redux Setup
import { Provider } from "react-redux";

//thunk setup
// import { createStore, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk';
// const store = createStore(rootReducer, applyMiddleware(thunk));

// import { createStore } from 'redux'; //ไม่ได้ใช้ persist
// import rootReducer from "./redux/reducers/index"; //ไม่ได้ใช้ persist

// const store = createStore(rootReducer) //ไม่ได้ใช้ persist
import configureStore from "./redux/configureStore";

//Customer
import IndexCustomer from "./pages/customer/IndexCustomer";
import CreateCustomer from "./pages/customer/CreateCustomer";
import EditCustomer from "./pages/customer/EditCustomer";

//Product
import IndexCategory from "./pages/product/IndexCategory";
import CreateCategory from "./pages/product/CreateCategory";
import EditCategory from "./pages/product/EditCategory";
import IndexProduct from "./pages/product/IndexProduct";
import CreateProduct from "./pages/product/CreateProduct";
import EditProduct from "./pages/product/EditProduct";
import PdfAddress from "./pages/report/PdfAddress";
import PdfAddress2F from "./pages/report/PdfAddress2F";
import IndexUnit from "./pages/product/IndexUnit";
import CreateUnit from "./pages/product/CreateUnit";
import EditUnit from "./pages/product/EditUnit";

//Sale
import IndexQuotation from "./pages/sale/IndexQuotation";
import CreateQuotation from "./pages/sale/CreateQuotation";
import EditQuotation from "./pages/sale/EditQuotation";
import PrintQuotation from "./pages/report/PrintQuotation";
import IndexInvoice from "./pages/sale/IndexInvoice";
import CreateInvoice from "./pages/sale/CreateInvoice";
import EditInvoice from "./pages/sale/EditInvoice";
import PrintInvoice from "./pages/report/PrintInvoice";

//Purchase
import IndexVendor from "./pages/purchase/IndexVendor";
import CreateVendor from "./pages/purchase/CreateVendor";
import EditVendor from "./pages/purchase/EditVendor";
import CreatePurchase from "./pages/purchase/CreatePurchase";
import IndexPurchase from "./pages/purchase/IndexPurchase";
import EditPurchase from "./pages/purchase/EditPurchase";
import PrintPurchaseTH from "./pages/report/PrintPurchaseTH";

const { store } = configureStore(); //redux persist

const queryClient = new QueryClient();

function App() {
  return (    
    <Provider store={store}>
      <UserStoreProvider>
        <ToastProvider
          placement="top-center"
          autoDismiss
          autoDismissTimeout={3000}
        >
          <QueryClientProvider client={queryClient}>
            <Router>
              <NavBar />
              <Switch>
                <Route exact path="/">
                  <HomePage />
                </Route>

                <Route path="/register">
                  <RegisterPage />
                </Route>
                <Route path="/login">
                  <LoginPage />
                </Route>

                <Route
                  path="/customer"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexCustomer />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateCustomer />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditCustomer />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/pdf/:id`} exact>
                        <PdfAddress />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/pdf2/:id`} exact>
                        <PdfAddress2F />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>

                {/* Product */}
                <Route
                  path="/category"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexCategory />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateCategory />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditCategory />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>

                <Route
                  path="/product"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexProduct />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateProduct />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditProduct />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>

                <Route
                  path="/unit"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexUnit />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateUnit />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditUnit />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>

                {/* sale */}
                <Route
                  path="/quotation"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexQuotation />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateQuotation />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditQuotation />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/print/:id`} exact>
                        <PrintQuotation />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>
                <Route
                  path="/invoice"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexInvoice />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateInvoice />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditInvoice />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/print/:id/:type`} exact>
                        <PrintInvoice />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>

                {/* Purchase */}
                <Route
                  path="/vendor"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexVendor />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreateVendor />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditVendor />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>
                <Route
                  path="/purchase/TH"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexPurchase country="TH" countryName="ในประเทศ" />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreatePurchase
                          country="TH"
                          countryName="ในประเทศ"
                          bg="Ivory"
                        />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditPurchase />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/print/:id`} exact>
                        <PrintPurchaseTH />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>
                <Route
                  path="/purchase/OT"
                  render={({ match: { url } }) => (
                    <>
                      <PrivateRoute path={`${url}/`} exact>
                        <IndexPurchase country="OT" countryName="ต่างประเทศ" />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/create`} exact>
                        <CreatePurchase
                          country="OT"
                          countryName="ต่างประเทศ"
                          bg="Azure"
                        />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/edit/:id`} exact>
                        <EditPurchase />
                      </PrivateRoute>
                      <PrivateRoute path={`${url}/print/:id`} exact>
                        <PrintPurchaseTH />
                      </PrivateRoute>
                    </>
                  )}
                ></Route>
              </Switch>
              <Footer />
            </Router>
          </QueryClientProvider>
        </ToastProvider>
      </UserStoreProvider>
    </Provider>
  );
}

export default App;
