import React from "react";

import { Container, Form, Button, Col, Row, Image } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";

const schema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อ"),
  code: yup.string().required("กรุณาป้อนข้อมูลรหัสสินค้า"),
  fda_code: yup.string().required("กรุณากรอกเลขที่(อย.)"),
  picture: yup.mixed().required("กรุณาอับโหลดรูปภาพ"),
  category: yup.object().nullable().required("กรุณาเลือกประเภทสินค้า"),
  mode: yup.object().nullable().required("กรุณาเลือกโหมดสินค้า"),
  unit_m: yup.object().nullable().required("กรุณาเลือกหน่วยหลัก"),
  unit_n: yup.object().nullable().required("กรุณาเลือกหน่วยย่อย"),
  unit_scale: yup
    .number()
    .typeError("กรุณากรอกตัวเลข")
    .positive("จำนวนต้องมากกว่า 0")
    .required("กรุณากรอกจำนวนต่อหน่วยหลัก"),
  min_value: yup
    .number()
    .typeError("กรุณากรอกตัวเลข")
    .positive("จำนวนต้องมากกว่า 0")
    .required("กรุณากรอกจำนวนอย่างน้อย"),
});

const EditProduct = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();

  const _token = JSON.parse(localStorage.getItem("token"));

  const { register, handleSubmit, control, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const [Catagories, setCatagories] = React.useState([]);

  const categoryOptions = Catagories.map((anObjectMapped, index) => {
    return { value: anObjectMapped._id, label: anObjectMapped.name };
  });

  const [category, setCategory] = React.useState([]);
  const [mode, setMode] = React.useState([]);
  const [newcode_unit, setNewcodeU] = React.useState([]);
  const [unit_m, setUnitM] = React.useState([]);
  const [unit_n, setUnitN] = React.useState([]);
  const [picture, setPicture] = React.useState([]);

  const [modeOptions, setModeOptions] = React.useState([]);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const [newcode_unitOptions, setNewcode_UnitOptions] = React.useState([]);

  const getData = React.useCallback(async () => {
    const resp_category = await axios.get(
      `${process.env.REACT_APP_API_URL}category/all/`
    );
    setCatagories(resp_category.data.data);

    const apiUrl_unit = `${process.env.REACT_APP_API_URL}unit/all`;
    const resp_unit = await axios.get(apiUrl_unit, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    // console.log(resp_unit.data.data);
    setNewcode_UnitOptions(
      resp_unit.data.data.map((anObjectMapped, index) => {
        return {
          value: anObjectMapped.name,
          label: anObjectMapped.name_detail,
          new_code: anObjectMapped.new_code,
        };
      })
    );

    const apiUrl = `${process.env.REACT_APP_API_URL}product/${id}`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });

    // const resp = await axios.get(
    //   `${process.env.REACT_APP_API_URL}product/${id}`
    // );
    setValue("code", resp.data.data.code);
    setValue("fda_code", resp.data.data.fda_code);
    setValue("name", resp.data.data.name);
    setValue("unit_scale", resp.data.data.unit_scale);
    setValue("min_value", resp.data.data.min_value);

    setValue("category", { value: resp.data.data.category });
    setCategory(resp.data.data.category);

    setValue("mode", { value: resp.data.data.mode });
    setMode(resp.data.data.mode);

    setValue("unit_m", {
      value: resp.data.data.unit_m,
      new_code: resp.data.data.newcode_unit,
    });
    setUnitM(resp.data.data.unit_m);
    // setValue("unit_m", { new_code: resp.data.data.newcode_unit });
    setNewcodeU(resp.data.data.newcode_unit);

    setValue("unit_n", { value: resp.data.data.unit_n });
    setUnitN(resp.data.data.unit_n);

    setPicture(resp.data.data.picturewithdomain);

    // Seting Api
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Products`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting.data;
    // console.log(resp_setting.data.setting);

    setModeOptions(
      setting_name.modes.map((anObjectMapped, index) => {
        return { value: anObjectMapped.value, label: anObjectMapped.label };
      })
    );

    setUnitOptions(
      setting_name.Units.map((anObjectMapped, index) => {
        return { value: anObjectMapped.value, label: anObjectMapped.label };
      })
    );
    // END Seting Api
  }, [_token.access_token, id, setValue]);

  React.useEffect(() => {
    // console.log("use effect");
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    console.log(data);

    const showToast = (msg) => {
      // console.log(msg);
      addToast(msg.data.message, { appearance: "success" });
    };

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}product/`;

      if (data.picture.length === 1) {
        let fileUpload = data.picture[0];
        const reader = new FileReader();

        reader.readAsDataURL(fileUpload);

        reader.onload = async (e) => {
          let base64Image = e.target.result;

          const resp = await axios.put(
            apiUrl,
            {
              id: id,
              code: data.code,
              fda_code: data.fda_code,
              name: data.name,
              category: data.category.value,
              mode: data.mode.value,
              newcode_unit: data.unit_m.new_code,
              unit_m: data.unit_m.value,
              unit_scale: data.unit_scale,
              unit_n: data.unit_n.value,
              min_value: data.min_value,
              picture: base64Image,
            },
            {
              headers: {
                Authorization: "Bearer " + _token.access_token,
              },
            }
          );

          showToast(resp);
        };
      } else {
        const resp = await axios.put(
          apiUrl,
          {
            id: id,
            code: data.code,
            fda_code: data.fda_code,
            name: data.name,
            category: data.category.value,
            mode: data.mode.value,
            newcode_unit: data.unit_m.new_code,
            unit_m: data.unit_m.value,
            unit_scale: data.unit_scale,
            unit_n: data.unit_n.value,
            min_value: data.min_value,
            picture: "",
          },
          {
            headers: {
              Authorization: "Bearer " + _token.access_token,
            },
          }
        );
        showToast(resp);
      }

      history.replace("/product");
    } catch (error) {
      //   console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  return (
    <Container className="mt-4">
      <h2>แก้ไขข้อมูลสินค้า {`->`} </h2>
      <Row className="mt-3">
        <Col xs={12} md={12}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="fda_code">
              <Form.Label>เลขที่ (อย.)</Form.Label>
              <Form.Control
                type="text"
                name="fda_code"
                autoComplete="off"
                defaultValue="-"
                ref={register}
                className={`form-control ${
                  errors.fda_code ? "is-invalid" : ""
                }`}
              />
              {errors.fda_code && (
                <Form.Control.Feedback type="invalid">
                  {errors.fda_code.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Row>
              <Col sm={4}>
                <Form.Group controlId="code">
                  <Form.Label>รหัสสินค้า</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.code ? "is-invalid" : ""
                    }`}
                  />
                  {errors.code && (
                    <Form.Control.Feedback type="invalid">
                      {errors.code.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group controlId="name">
                  <Form.Label>ชื่อสินค้า</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={6}>
                <Form.Label>ประเภทสินค้า</Form.Label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      value={categoryOptions.filter(
                        (option) => option.value === category
                      )}
                      onChange={(e) => {
                        setValue("category", e);
                        setCategory(e.value);
                      }}
                      placeholder="ประเภทสินค้า.."
                      className="mb-2"
                      options={categoryOptions}
                    />
                  )}
                />
                {errors?.category && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.category.message}
                  </p>
                )}
              </Col>
              <Col sm={6}>
                <Form.Label>โหมดสินค้า</Form.Label>
                <Controller
                  name="mode"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="โหมดสินค้า.."
                      className="mb-2"
                      options={modeOptions}
                      value={modeOptions.filter(
                        (option) => option.value === mode
                      )}
                      onChange={(e) => {
                        setValue("mode", e);
                        setMode(e.value);
                      }}
                    />
                  )}
                />
                {errors?.mode && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.mode.message}
                  </p>
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm={5}>
                <Form.Label>หน่วยหลัก</Form.Label>
                <Controller
                  name="unit_m"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder={`เลือกหน่วยหลัก..`}
                      className="mb-2"
                      options={newcode_unitOptions}
                      value={
                        newcode_unit !== "-"
                          ? newcode_unitOptions.filter(
                              (option) => option.new_code === newcode_unit
                            )
                          : newcode_unitOptions.filter(
                              (option) =>
                              option.value === unit_m &&
                              option.new_code === newcode_unit
                            )
                      }
                      onChange={(e) => {
                        // console.log(e);
                        setValue("unit_m", e);
                        setUnitM(e.value);
                        setNewcodeU(e.new_code);
                      }}
                    />
                  )}
                />
                {errors?.unit_m && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.unit_m.message}
                  </p>
                )}
              </Col>
              <Col sm={2}>
                <Form.Group controlId="unit_scale">
                  <Form.Label>จำนวนต่อหน่วยหลัก</Form.Label>
                  <Form.Control
                    type="number"
                    name="unit_scale"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.unit_scale ? "is-invalid" : ""
                    }`}
                  />
                  {errors.unit_scale && (
                    <Form.Control.Feedback type="invalid">
                      {errors.unit_scale.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Label>หน่วยย่อย</Form.Label>
                <Controller
                  name="unit_n"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="เลือกหน่วยย่อย.."
                      className="mb-2"
                      options={unitOptions}
                      value={unitOptions.filter(
                        (option) => option.value === unit_n
                      )}
                      onChange={(e) => {
                        setValue("unit_n", e);
                        setUnitN(e.value);
                      }}
                    />
                  )}
                />
                {errors?.unit_n && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.unit_n.message}
                  </p>
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm={2}>
                <Form.Group controlId="min_value">
                  <Form.Label>จำนวนอย่างน้อย</Form.Label>
                  <Form.Control
                    type="number"
                    name="min_value"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.min_value ? "is-invalid" : ""
                    }`}
                  />
                  {errors.min_value && (
                    <Form.Control.Feedback type="invalid">
                      {errors.min_value.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Label>อัพโหลดรูปภาพ</Form.Label>
                <Form.File
                  type="file"
                  name="picture"
                  // required
                  ref={register}
                  // onChange={pictureChange}
                  accept=".png,.jpg,.jpeg,"
                  className={`position-relative ${
                    errors.picture ? "is-invalid" : ""
                  }`}
                  isInvalid={!!errors.picture}
                  feedback={errors.picture}
                  feedbackTooltip
                />
              </Col>
              <Col sm={8} md={{ span: 3, offset: 4 }}>
                <Image src={picture} thumbnail alt={picture} width={150} />
              </Col>
            </Form.Row>

            <br />
            <Button variant="warning" type="submit">
              แก้ไข
            </Button>

            <Button
              className="ml-3"
              variant="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default EditProduct;
