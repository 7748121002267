import React from "react";
import { format } from "date-fns";
import {
  Spinner,
  Table,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import axios from "axios";
import { BsSearch, BsPencil, BsFillTrashFill } from "react-icons/bs";
import { MdPrint } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

import { useHistory } from "react-router";

const pageSize = 15;

const IndexInvoice = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  //   const cancelToken = React.useRef(null);
  const searchInput = React.useRef(null);

  //pagination
  const [page, setPage] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [i_name, setCName] = React.useState("");

  const _token = JSON.parse(localStorage.getItem("token"));

  const getData = async (page) => {
    try {
      if (i_name === "") {
        // setLoading(true);
        setList([]);
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}invoice?page=${page}&size=${pageSize}&i_name=${i_name}`;
      const resp = await axios.get(apiUrl, {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
        // cancelToken: cancelToken.current.token,
      });
      //   resp.data.data !== [] && (setProduct(resp.data.data))
      setList(resp.data.data);
      // console.log(resp.data.data)
      setTotal(resp.data.meta.pagination.total);
    } catch (error) {
      // console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    // cancelToken.current = axios.CancelToken.source();

    // getData(page);

    // console.log('use effect')

    searchInput.current.focus();

    // return () => {
    //   cancelToken.current.cancel();
    // };
    const timer = setTimeout(() => {
      getData(page);
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, i_name]);

  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        <p>เกิดข้อผิดพลาดจาก Server กรุณาลองใหม่</p>
        <p>{error.response.data.message}</p>
      </div>
    );
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const customerSearch = (change) => {
    // console.log(change.target.value);
    setCName(change.target.value);
  };

  const setTHB = (b) => {
    let bath = Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
    }).format(b);

    return bath.substring(1);
  };

  // const confirmDel2 = (iv_id, iv_title) => {
  //   Swal.fire({
  //     title: `${iv_title}`,
  //     text: "คุณต้องการลบใช่ หรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "ใช่ ฉันต้องการลบ",
  //     cancelButtonText: "ยกเลิก",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // Swal.fire(
  //       //   `${iv_title}`,
  //       //   'ถูกลบแล้ว',
  //       //   'success'
  //       // )
  //       delIV(iv_id, iv_title);
  //       // window.location.reload(false);
  //     }
  //   });
  // };

  const delIV = async (iv_id, iv_title) => {
    // console.log(iv_id);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}quotation/delete_iv`;
      const resp = await axios.post(
        apiUrl,
        {
          id: iv_id,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );

      console.log(resp.data);

      Swal.fire(`${iv_title}`, "ถูกลบแล้ว", "success");

      getData(page);
    } catch (error) {
      // console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  const confirmDel = async (iv_id, iv, customer, iv_title) => {
    const { value: pass } = await Swal.fire({
      title: `${iv}`,
      input: "password",
      inputLabel: `${customer}`,
      confirmButtonColor: "Red",
      confirmButtonText: 'ลบ',
      inputPlaceholder: "Enter your password",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
    });

    if (pass.toUpperCase() === iv) {
      // Swal.fire(`Entered password: ${iv}`);
      // console.log(_id);
      delIV(iv_id, iv_title);
    } else {
      Swal.fire("Error", "รหัสผ่านไม่ถูกต้อง", "error");
    }
  };

  // const selPrint1 = (iv_title,_id) => {
  //   Swal.fire({
  //     title: `${iv_title}`,
  //     showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: 'วันที่+บช.',
  //     denyButtonText: `ไม่ลงวันที่`,
  //     denyButtonColor: '#28a745',
  //     cancelButtonText: 'ลงวันที่'
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       window.open(`/invoice/print/${_id}/bank`, "_blank")
  //     } else if (result.isDenied) {
  //       window.open(`/invoice/print/${_id}/nodate`, "_blank")
  //     } else if (result.dismiss) {
  //       window.open(`/invoice/print/${_id}/none`, "_blank")
  //     }
  //   })
  // };

  const selPrint = async (iv_title, _id) => {
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          bank: "วันที่+บช.",
          nodate: "ไม่ลงวันที่",
          none: "ลงวันที่",
        });
      }, 500);
    });

    const { value: type } = await Swal.fire({
      title: `${iv_title}`,
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "กรุณาเลือกรูปแบบการพิมพ์";
        }
      },
      showCancelButton: true,
      confirmButtonText: "พิมพ์",
      confirmButtonColor: "#28a745",
      cancelButtonText: `ยกเลิก`,
    });

    if (type) {
      window.open(`/invoice/print/${_id}/${type}`, "_blank");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2>รายการใบกำกับภาษี</h2>
            <div className="row">
              <div className="col-sm-8">
                <button
                  className="btn btn-success mb-3"
                  onClick={() => {
                    history.push("/invoice/create");
                  }}
                >
                  สร้างใบกำกับภาษี
                </button>
              </div>
              <div className="col-sm-4">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search..."
                    value={i_name}
                    ref={searchInput}
                    onChange={customerSearch}
                    // onChange = {(event) => this.setCName({email: event.target.value })}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <BsSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr className="text-center">
                  <th>วันที่</th>
                  <th>เลขที่</th>
                  <th>ชื่อลูกค้า</th>
                  <th>จังหวัด</th>
                  <th>ยอดเงิน</th>
                  <th>พนักงานขาย</th>
                  <th>เครื่องมือ</th>
                </tr>
              </thead>
              <tbody>
                {list.map((i, index) => {
                  return (
                    <tr key={i._id}>
                      <td>{format(new Date(i.dateIV), "dd-MM-yy")}</td>
                      <td>{i.iv}</td>
                      <td>
                        {i.customer.name.length > 55
                          ? i.customer.name.slice(0, 55).concat("...")
                          : i.customer.name}
                      </td>
                      <td>{i.customer.province}</td>
                      <td className="text-right">
                        {setTHB(i.quotation.total)}
                      </td>
                      <td className="text-right">{i.sale.name}</td>
                      <td>
                        <Button
                          className="ml-2"
                          variant="info"
                          size="sm"
                          onClick={() => history.push(`/invoice/edit/${i._id}`)}
                        >
                          <BsPencil />
                        </Button>
                        <Button
                          className="ml-2"
                          variant="warning"
                          size="sm"
                          onClick={() =>
                            // history.replace(`/invoice/print/${i._id}`)
                            window.open(
                              `/quotation/print/${i.quotation._id}`,
                              "_blank"
                            )
                          }
                        >
                          <MdPrint />
                          QT
                        </Button>
                        <Button
                          className="ml-2"
                          variant="success"
                          size="sm"
                          onClick={() =>
                            // history.replace(`/invoice/print/${i._id}`)
                            // window.open(`/invoice/print/${i._id}/1`, "_blank")
                            selPrint(`${i.iv} - ${i.customer.name}`, `${i._id}`)
                          }
                        >
                          <MdPrint />
                          IV
                        </Button>

                        <Button
                          className="ml-4"
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            // history.replace(`/invoice/print/${i._id}`)
                            // console.log(`${i.quotation._id}`)
                            confirmDel(
                              `${i.quotation._id}`,
                              `${i.iv}`,
                              `${i.customer.name}`,
                              `${i.iv} - ${i.customer.name}`
                            )
                          }
                        >
                          <BsFillTrashFill />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              // prevPageText="ก่อนหน้า"
              firstPageText="หน้าแรก"
              lastPageText="หน้าสุดท้าย"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexInvoice;
