import React from "react";
import { format } from "date-fns";
import {
  Spinner,
  Table,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import axios from "axios";
import {
  BsSearch,
  BsPencil,
  BsFillFileEarmarkCheckFill,
  BsFillFileEarmarkArrowUpFill,
} from "react-icons/bs";
import { MdPrint } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

import { useHistory } from "react-router";

const pageSize = 15;

const IndexQuotation = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [product, setProduct] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  //   const cancelToken = React.useRef(null);
  const searchInput = React.useRef(null);

  //pagination
  const [page, setPage] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [q_name, setCName] = React.useState("");

  const _token = JSON.parse(localStorage.getItem("token"));

  const getData = async (page) => {
    try {
      if (q_name === "") {
        // setLoading(true);
        setProduct([]);
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}quotation?page=${page}&size=${pageSize}&q_name=${q_name}`;
      const resp = await axios.get(apiUrl, {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
        // cancelToken: cancelToken.current.token,
      });
      //   resp.data.data !== [] && (setProduct(resp.data.data))
      setProduct(resp.data.data);
      // console.log(resp.data.data)
      setTotal(resp.data.meta.pagination.total);
    } catch (error) {
      // console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    // cancelToken.current = axios.CancelToken.source();

    // getData(page);****

    // console.log('use effect')

    searchInput.current.focus();

    // return () => {
    //   cancelToken.current.cancel();
    // };
    const timer = setTimeout(() => {
      getData(page);
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, q_name]);

  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        <p>เกิดข้อผิดพลาดจาก Server กรุณาลองใหม่</p>
        <p>{error.response.data.message}</p>
      </div>
    );
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const customerSearch = (change) => {
    // console.log(change.target.value);
    setCName(change.target.value);
  };

  const setTHB = (b) => {
    let bath = Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
    }).format(b);

    return bath.substring(1);
  };

  const quotation2invoice = async (qt,customer, total, _id) => {
    const { value: money } = await Swal.fire({
      title: `${qt}`,
      input: 'text',
      inputLabel: `${customer}`,
      inputPlaceholder: 'โปรดใส่จำนวนเงินให้ถูกต้อง',
      inputAttributes: {
        autocomplete: 'off'
      }
      // inputAttributes: {
      //   maxlength: 10,
      //   autocapitalize: 'off',
      //   autocorrect: 'off'
      // }
    })
    
    if (money === total) {
      // Swal.fire(`Entered password: ${total}`)
      // console.log(_id);
      qtSubmit(_id)
    }
  };

  const qtSubmit = async (qt_id) => {
    // console.log(data);
    try {
      //   console.log(nowDate);
      const apiUrl = `${process.env.REACT_APP_API_URL}quotation/quotation2invoice`;
      const resp = await axios.post(
        apiUrl,
        {
          _id: qt_id,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // alert(resp.data.message);

      addToast(resp.data.message, { appearance: "success" });
      getData(page);
      // history.push("/quotation");
    } catch (error) {
      // console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2>รายการใบเสนอราคา</h2>
            <div className="row">
              <div className="col-sm-8">
                <button
                  className="btn btn-warning mb-3"
                  onClick={() => {
                    history.push("/quotation/create");
                  }}
                >
                  สร้างใบเสนอราคา
                </button>
              </div>
              <div className="col-sm-4">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search..."
                    value={q_name}
                    ref={searchInput}
                    onChange={customerSearch}
                    // onChange = {(event) => this.setCName({email: event.target.value })}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <BsSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>วันที่</th>
                  <th>เลขที่</th>
                  <th>ชื่อลูกค้า</th>
                  <th>จังหวัด</th>
                  <th>ยอดเงิน</th>
                  <th>เครื่องมือ</th>
                </tr>
              </thead>
              <tbody>
                {product.map((q, index) => {
                  return (
                    <tr key={q._id}>
                      <td>{format(new Date(q.dateQT), "dd-MM-yy")}</td>
                      <td>{q.qt}</td>
                      <td>
                        {q.customer.name.length > 55
                          ? q.customer.name.slice(0, 55).concat("...")
                          : q.customer.name}
                      </td>
                      <td>{q.customer.province}</td>
                      <td className="text-right">{setTHB(q.total)}</td>
                      {/* <td>{q.total}</td> */}
                      <td>
                        <Button
                          className="ml-2"
                          variant="info"
                          size="sm"
                          onClick={() =>
                            history.push(`/quotation/edit/${q._id}`)
                          }
                        >
                          <BsPencil />
                        </Button>
                        <Button
                          className="ml-2"
                          variant="warning"
                          size="sm"
                          onClick={() =>
                            window.open(`/quotation/print/${q._id}`)
                          }
                        >
                          <MdPrint />
                          QT
                        </Button>
                        {q.iv !== "0" ? (
                          <Button
                            className="ml-2"
                            variant="success"
                            size="sm"
                          >
                            <BsFillFileEarmarkCheckFill /> {q.iv}
                          </Button> 
                        ) : (
                          <Button
                            className="ml-2"
                            variant="secondary"
                            size="sm"
                            onClick={() => quotation2invoice(`${q.qt}`,`${q.customer.name}`,`${q.total}`,`${q._id}`)}
                          >
                            <BsFillFileEarmarkArrowUpFill />
                            ออก IV
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              // prevPageText="ก่อนหน้า"
              firstPageText="หน้าแรก"
              lastPageText="หน้าสุดท้าย"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexQuotation;
