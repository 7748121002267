import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";

const schema = yup.object().shape({
  code: yup.string().required("กรุณากรอกรหัสผู้จำหน่าย"),
  fda_code: yup.string().required("กรุณากรอกเลขที่(อย.)"),
  taxId: yup.string().required("กรุณาป้อนข้อมูลเลขประจำตัวผู้เสียภาษี"),
  name: yup.string().required("กรุณากรอกชื่อ"),
  address: yup.string().required("กรุณาป้อนข้อมูลที่อยู่"),
  tel: yup.string().required("กรุณาป้อนข้อมูลเบอร์โทรศัพท์"),
  fax: yup.string().required("กรุณาป้อนข้อมูลFax"),
  contact: yup.string().required("กรุณาป้อนข้อมูลผู้ติดต่อ"),
  email: yup.string().required("กรุณากรอกe-mail"),
  country: yup.object().nullable().required("กรุณาเลือกประเทศ"),
});

const EditVendor = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();

  const _token = JSON.parse(localStorage.getItem("token"));

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [Country, setCountry] = React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);

  const getData = React.useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}vendor/${id}`;
    const resp = await axios.get(apiUrl, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
      // cancelToken: cancelToken.current.token,
    });
    // console.log(resp.data);

    //setValue
    setValue("name", resp.data.data.name);
    setValue("code", resp.data.data.code);
    setValue("taxId", resp.data.data.taxId);
    setValue("address", resp.data.data.address);
    setValue("fda_code", resp.data.data.fda_code);
    setValue("tel", resp.data.data.tel);
    setValue("fax", resp.data.data.fax);
    setValue("contact", resp.data.data.contact);
    setValue("email", resp.data.data.email);

    setValue("country", { value: resp.data.data.country });
    setCountry(resp.data.data.country);

    // Seting Api
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Purchases`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting.data;
    // console.log(resp_setting.data.setting.data.Country);
    
    setCountryOptions(
        setting_name.Country.map((anObjectMapped, index) => {
          return { value: anObjectMapped.value, label: anObjectMapped.label };
        })
    );
    // END Seting Api

  }, [_token.access_token, id, setValue]);

  React.useEffect(() => {
    // console.log("use effect");
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      // console.log(data.country);
      const apiUrl = `${process.env.REACT_APP_API_URL}vendor`;
      const resp = await axios.put(
        apiUrl,
        {
          id: id,
          code: data.code,
          fda_code: data.fda_code,
          taxId: data.taxId,
          name: data.name,
          address: data.address,
          country: data.country.value,
          tel: data.tel,
          fax: data.fax,
          contact: data.contact,
          email: data.email,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // alert(resp.data.message);

      addToast(resp.data.message, { appearance: "success" });
      history.replace("/vendor");
    } catch (error) {
      // console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  return (
    <Container className="mt-4">
      <h2>เพิ่มข้อมูลผู้จำหน่าย</h2>
      <Row className="mt-3">
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="fda_code">
              <Form.Label>เลขที่ (อย.)</Form.Label>
              <Form.Control
                type="text"
                name="fda_code"
                autoComplete="off"
                defaultValue="-"
                ref={register}
                className={`form-control ${
                  errors.fda_code ? "is-invalid" : ""
                }`}
              />
              {errors.fda_code && (
                <Form.Control.Feedback type="invalid">
                  {errors.fda_code.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Row>
              <Col sm={4}>
                <Form.Group controlId="code">
                  <Form.Label>รหัสผู้จำหน่าย</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.code ? "is-invalid" : ""
                    }`}
                  />
                  {errors.code && (
                    <Form.Control.Feedback type="invalid">
                      {errors.code.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group controlId="taxId">
                  <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                  <Form.Control
                    type="text"
                    name="taxId"
                    autoComplete="off"
                    defaultValue="-"
                    ref={register}
                    className={`form-control ${
                      errors.taxId ? "is-invalid" : ""
                    }`}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Group controlId="name">
              <Form.Label>ชื่อผู้จำหน่าย</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Control
                type="text"
                name="address"
                autoComplete="off"
                placeholder="ที่อยู่..."
                ref={register}
                className={`form-control ${errors.address ? "is-invalid" : ""}`}
              />
              {errors.address && (
                <Form.Control.Feedback type="invalid">
                  {errors.address.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Row>
              <Col sm={6}>
                <Form.Group controlId="tel">
                  <Form.Control
                    type="text"
                    name="tel"
                    autoComplete="off"
                    placeholder="โทรศัพท์.."
                    ref={register}
                    className={`form-control ${errors.tel ? "is-invalid" : ""}`}
                  />
                  {errors.tel && (
                    <Form.Control.Feedback type="invalid">
                      {errors.tel.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="ประเทศ.."
                      className="mb-2"
                      options={countryOptions}
                      value={countryOptions.filter(
                        (option) => option.value === Country
                      )}
                      onChange={(e) => {
                        // console.log(e);
                        setCountry(e.value);                        
                        setValue("country", e);
                        // console.log(Country);
                      }}
                    />
                  )}
                />
                {errors?.country && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.country.message}
                  </p>
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm={8}>
                <Form.Group controlId="contact">
                  <Form.Label>ผู้ติดต่อ/contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.contact ? "is-invalid" : ""
                    }`}
                  />
                  {errors.contact && (
                    <Form.Control.Feedback type="invalid">
                      {errors.contact.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="fax">
                  <Form.Label>Fax</Form.Label>
                  <Form.Control
                    type="text"
                    name="fax"
                    autoComplete="off"
                    defaultValue="-"
                    ref={register}
                    className={`form-control ${errors.fax ? "is-invalid" : ""}`}
                  />
                  {errors.fax && (
                    <Form.Control.Feedback type="invalid">
                      {errors.fax.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Group controlId="email">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type="text"
                name="email"
                autoComplete="off"
                defaultValue="-"
                ref={register}
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <br />
            <Button variant="primary" type="submit">
              แก้ไขผู้จำหน่าย
            </Button>

            <Button
              className="ml-3"
              variant="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default EditVendor;
