import React from "react";
import {
  Spinner,
  Table,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import axios from "axios";
import { BsSearch, BsPencil } from "react-icons/bs";

import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const pageSize = 15;

const IndexUnit = () => {
  const history = useHistory();

  const [unit, setUnit] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const cancelToken = React.useRef(null);
  const searchInput = React.useRef(null);

  //pagination
  const [page, setPage] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [c_name, setCName] = React.useState("");

  // const _token = JSON.parse(localStorage.getItem("token"));
  const _token = JSON.parse(localStorage.getItem("token"));

  const getData = async (page) => {
    try {
      if (c_name === "") {
        // setLoading(true);
        setUnit([]);
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}unit?page=${page}&size=${pageSize}&c_name=${c_name}`;
      const resp = await axios.get(apiUrl, {
        headers: {
          Authorization: "Bearer " + _token.access_token,
        },
        // cancelToken: cancelToken.current.token,
      });
      // console.log(resp.data.data);
      resp.data.data !== [] && setUnit(resp.data.data);
      setTotal(resp.data.meta.pagination.total);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  //Redux
  const profileRedux = useSelector((state) => state.authReducer.profile);
  // console.log(profileRedux)

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();

    getData(page);

    // console.log('use effect')

    searchInput.current.focus();

    return () => {
      cancelToken.current.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, c_name]);

  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        <p>เกิดข้อผิดพลาดจาก Server กรุณาลองใหม่</p>
        <p>{error.response.data.message}</p>
      </div>
    );
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const unitSearch = (change) => {
    // console.log(change.target.value);
    setCName(change.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2>รายชื่อหน่วย</h2>
            <div className="row">
              <div className="col-sm-8">
                <button
                  className="btn btn-warning mb-3"
                  onClick={() => {
                    history.push("/unit/create");
                  }}
                >
                  เพิ่มหน่วย
                </button>
                <button
                  className="btn btn-secondary mb-3 ml-2"
                  onClick={() => {
                    history.push("/product");
                  }}
                >
                  ไปหน้าสินค้า
                </button>
              </div>
              <div className="col-sm-4">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search..."
                    value={c_name}
                    ref={searchInput}
                    onChange={unitSearch}
                    // onChange = {(event) => this.setCName({email: event.target.value })}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <BsSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>new_code</th>
                  <th>ชื่อหน่วย</th>
                  <th>รายละเอียด</th>
                  <th>เครื่องมือ</th>
                </tr>
              </thead>
              <tbody>
                {unit.map((c, index) => {
                  return (
                    <tr key={c._id}>
                      <td>{c.new_code}</td>
                      <td>{c.name}</td>
                      <td>{c.detail}</td>
                      <td>
                        {["admin"].includes(profileRedux.role) ? (
                          <Button
                            className="ml-2"
                            variant="info"
                            size="sm"
                            onClick={() => history.push(`/unit/edit/${c._id}`)}
                          >
                            <BsPencil />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              // prevPageText="ก่อนหน้า"
              firstPageText="หน้าแรก"
              lastPageText="หน้าสุดท้าย"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexUnit;
