import React from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import ProvinceLists from "../../resources/data/provinces.json";
import DistrictLists from "../../resources/data/districts.json";
import SubDistrictLists from "../../resources/data/sub_districts.json";
// import InputAddress from "../../components/AutoComplete/InputAddress";
// import typeCustomers from "./typeCustomers.json";

const schema = yup.object().shape({
  name: yup.string().required("กรุณากรอกชื่อ"),
  code: yup.string().required("กรุณาป้อนข้อมูลรหัสลูกค้า"),
  fda_code: yup.string().required("กรุณากรอกเลขที่(อย.)"),
  taxId: yup.string().required("กรุณาป้อนข้อมูลเลขประจำตัวผู้เสียภาษี"),
  address: yup.string().required("กรุณาป้อนข้อมูลที่อยู่ลูกค้า"),
  provinceSelect: yup.object().nullable().required("กรุณาเลือกจังหวัด"),
  districtSelect: yup.object().nullable().required("กรุณาเลือกตำบล"),
  subdistrictSelect: yup.object().nullable().required("กรุณาเลือกอำเภอ"),
  tel: yup.string().required("กรุณาป้อนข้อมูลเบอร์โทรศัพท์"),
  type: yup.object().nullable().required("กรุณาเลือกประเภทลูกค้า"),
  recipient: yup.string().required("กรุณาป้อนผู้รับสินค้า"),
  rec_tel: yup.string().required("กรุณาป้อนเบอร์โทรผู้รับสินค้า"),
  rec_tel_f: yup.string().required("กรุณาป้อนเบอร์โทรการเงิน"),
  owner: yup.string().required("กรุณาป้อนชื่อผู้บริหาร"),
  addr_line1: yup.string().required("addr_line1"),
  addr_line2: yup.string().required("addr_line2"),
});

const CreateCustomer = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [districtOptions, setDistrict] = React.useState([]);
  const [subdistrictOptions, setSubDistrict] = React.useState([]);

  const [addrDistrict, setAD] = React.useState([]);
  const [addrSD, setASD] = React.useState([]);
  const [typeCustomer, setType] = React.useState([]);

  const [customerOptions, setCustomerOptions] = React.useState([]);

  const [addrZipcode, setZipcode] = React.useState("");

  const _token = JSON.parse(localStorage.getItem("token"));

  // Seting Api (NO getdata)
  const getData = React.useCallback(async () => {
    const apiUrl_setting = `${process.env.REACT_APP_API_URL}setting/Customers`;
    const resp_setting = await axios.get(apiUrl_setting, {
      headers: {
        Authorization: "Bearer " + _token.access_token,
      },
    });
    const setting_name = resp_setting.data.setting;
    // console.log(resp_setting.data.setting);
    setCustomerOptions(
      setting_name.data.Type.map((anObjectMapped, index) => {
        return { value: anObjectMapped.value, label: anObjectMapped.label };
      })
    );
  }, [_token.access_token]);

  React.useEffect(() => {
    // console.log("use effect");
    getData();
  }, [getData]);

  // END Seting Api (NO getdata)

  const onSubmit = async (data) => {
    try {
      // console.log(data);
      const apiUrl = `${process.env.REACT_APP_API_URL}customer`;
      const resp = await axios.post(
        apiUrl,
        {
          code: data.code,
          fda_code: data.fda_code,
          taxId: data.taxId,
          name: data.name,
          address: data.address,
          province: data.provinceSelect.label,
          district: data.districtSelect.label,
          sub_district: data.subdistrictSelect.label,
          zipcode: data.zipcode,
          tel: data.tel,
          type: data.type.value,
          recipient: data.recipient,
          rec_tel: data.rec_tel,
          rec_tel_f: data.rec_tel_f,
          owner: data.owner,
          addr_line1: data.addr_line1,
          addr_line2: data.addr_line2,
        },
        {
          headers: {
            Authorization: "Bearer " + _token.access_token,
          },
        }
      );
      // alert(resp.data.message);

      addToast(resp.data.message, { appearance: "success" });
      history.replace("/customer");
    } catch (error) {
      console.log(error.response.data);
      addToast(error.response.data.error.message, { appearance: "error" });
    }
  };

  const provinceOptions = ProvinceLists.map((anObjectMapped, index) => {
    return { value: anObjectMapped.id, label: anObjectMapped.name_th };
  });

  const provinceChange = (change) => {
    setValue("provinceSelect", change, {
      // shouldDirty: true,
    });
    let district = DistrictLists.filter(function (el) {
      // console.log(el);
      return el.province_id === change.value && el.name_th.indexOf("*") <= -1;
    });

    const districtOptions = district.map((anObjectMapped, index) => {
      return { value: anObjectMapped.id, label: anObjectMapped.name_th };
    });

    // console.log(district);
    setDistrict(districtOptions);
    setAD([{ value: "", label: "เลือกอำเภอ" }]);
    setSubDistrict([]);
    setASD([{ value: "", label: "เลือกตำบล" }]);

    // console.log(districtOptions[0]);
  };

  const districtChange = (change) => {
    setValue("districtSelect", change, {
      //   shouldDirty: true,
    });

    setAD(change);
    let subdistrict = SubDistrictLists.filter(function (el) {
      return el.district_id === change.value && el.name_th.indexOf("*") <= -1;
    });

    const subdistrictOptions = subdistrict.map((anObjectMapped, index) => {
      return { value: anObjectMapped.zip_code, label: anObjectMapped.name_th };
    });

    setSubDistrict(subdistrictOptions);
    setASD([{ value: "", label: "เลือกตำบล" }]);
    // setASD(subdistrictOptions[0]);
  };

  const subdistrictChange = (change) => {
    setValue("subdistrictSelect", change, {
      //   shouldDirty: true,
    });

    setASD(change);

    setZipcode(change.value);
    // console.log(change.value);
  };

  const defineZipcode = (change) => {
    // console.log(change);
  };

  const typeChange = (change) => {
    setValue("type", change, {
      //   shouldDirty: true,
    });
    setType(change);
  };

  const compare = (a, b) => (a.label > b.label ? 1 : -1);

  return (
    <Container className="mt-4">
      <h2>เพิ่มข้อมูลลูกค้า</h2>
      <Row className="mt-3">
        <Col xs={12} md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="fda_code">
              <Form.Label>เลขที่ (อย.)</Form.Label>
              <Form.Control
                type="text"
                name="fda_code"
                autoComplete="off"
                defaultValue="-"
                ref={register}
                className={`form-control ${
                  errors.fda_code ? "is-invalid" : ""
                }`}
              />
              {errors.fda_code && (
                <Form.Control.Feedback type="invalid">
                  {errors.fda_code.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Row>
              <Col sm={4}>
                <Form.Group controlId="code">
                  <Form.Label>รหัสลูกค้า</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.code ? "is-invalid" : ""
                    }`}
                  />
                  {errors.code && (
                    <Form.Control.Feedback type="invalid">
                      {errors.code.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group controlId="taxId">
                  <Form.Label>เลขประจำตัวผู้เสียภาษี</Form.Label>
                  <Form.Control
                    type="text"
                    name="taxId"
                    autoComplete="off"
                    defaultValue="-"
                    ref={register}
                    className={`form-control ${
                      errors.taxId ? "is-invalid" : ""
                    }`}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Group controlId="name">
              <Form.Label>ชื่อลูกค้า</Form.Label>
              <Form.Control
                type="text"
                name="name"
                autoComplete="off"
                ref={register}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Control
                type="text"
                name="address"
                autoComplete="off"
                placeholder="ที่อยู่..."
                ref={register}
                className={`form-control ${errors.address ? "is-invalid" : ""}`}
              />
              {errors.address && (
                <Form.Control.Feedback type="invalid">
                  {errors.address.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Row>
              <Col>
                <Controller
                  name="provinceSelect"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="จังหวัด.."
                      className="mb-2"
                      options={provinceOptions.sort(compare)}
                      //   defaultValue={options[1]}
                      onChange={provinceChange}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  )}
                />
                {errors?.provinceSelect && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.provinceSelect.message}
                  </p>
                )}
              </Col>
              <Col>
                <Controller
                  name="districtSelect"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="อำเภอ.."
                      className="mb-2"
                      options={districtOptions.sort(compare)}
                      value={addrDistrict}
                      onChange={districtChange}
                    />
                  )}
                />
                {errors?.districtSelect && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.districtSelect.message}
                  </p>
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col>
                <Controller
                  name="subdistrictSelect"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="ตำบล.."
                      // className="mb-2"
                      options={subdistrictOptions.sort(compare)}
                      value={addrSD}
                      onChange={subdistrictChange}
                    />
                  )}
                />
                {errors?.subdistrictSelect && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.subdistrictSelect.message}
                  </p>
                )}
              </Col>
              <Col>
                <Form.Group controlId="zipcode">
                  <Form.Control
                    type="text"
                    name="zipcode"
                    placeholder="รหัสไปรษณีย์"
                    autoComplete="off"
                    value={addrZipcode}
                    onChange={defineZipcode}
                    ref={register}
                    className={`form-control ${
                      errors.zipcode ? "is-invalid" : ""
                    }`}
                  />
                  {errors.zipcode && (
                    <Form.Control.Feedback type="invalid">
                      {errors.zipcode.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm={6}>
                <Form.Group controlId="tel">
                  <Form.Control
                    type="text"
                    name="tel"
                    autoComplete="off"
                    placeholder="โทรศัพท์.."
                    ref={register}
                    className={`form-control ${errors.tel ? "is-invalid" : ""}`}
                  />
                  {errors.tel && (
                    <Form.Control.Feedback type="invalid">
                      {errors.tel.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={[]}
                  render={() => (
                    <Select
                      placeholder="ชนิดลูกค้า.."
                      className="mb-2"
                      options={customerOptions}
                      value={typeCustomer}
                      onChange={typeChange}
                    />
                  )}
                />
                {errors?.type && (
                  <p style={{ color: "Crimson", fontSize: "80%" }}>
                    {errors.type.message}
                  </p>
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm={8}>
                <Form.Group controlId="recipient">
                  <Form.Label>ผู้รับ</Form.Label>
                  <Form.Control
                    type="text"
                    name="recipient"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.recipient ? "is-invalid" : ""
                    }`}
                  />
                  {errors.recipient && (
                    <Form.Control.Feedback type="invalid">
                      {errors.recipient.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="rec_tel">
                  <Form.Label>เบอร์ผู้รับ</Form.Label>
                  <Form.Control
                    type="text"
                    name="rec_tel"
                    autoComplete="off"
                    ref={register}
                    className={`form-control ${
                      errors.rec_tel ? "is-invalid" : ""
                    }`}
                  />
                  {errors.rec_tel && (
                    <Form.Control.Feedback type="invalid">
                      {errors.rec_tel.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col sm={4}>
                <Form.Group controlId="rec_tel_f">
                  <Form.Label>เบอร์โทรการเงิน</Form.Label>
                  <Form.Control
                    type="text"
                    name="rec_tel_f"
                    autoComplete="off"
                    ref={register}
                    defaultValue="-"
                    className={`form-control ${
                      errors.rec_tel_f ? "is-invalid" : ""
                    }`}
                  />
                  {errors.rec_tel_f && (
                    <Form.Control.Feedback type="invalid">
                      {errors.rec_tel_f.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>

            <Form.Group controlId="owner">
              <Form.Label>ผู้มีอำนาจ</Form.Label>
              <Form.Control
                type="text"
                name="owner"
                autoComplete="off"
                ref={register}
                defaultValue="ผู้อำนวยการ"
                className={`form-control ${errors.owner ? "is-invalid" : ""}`}
              />
              {errors.owner && (
                <Form.Control.Feedback type="invalid">
                  {errors.owner.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="addr_line1">
              <Form.Label>ที่อยู่ (กรณีไม่ตรงกับบิล)</Form.Label>
              <Form.Control
                type="text"
                name="addr_line1"
                autoComplete="off"
                ref={register}
                defaultValue="-"
                className={`form-control ${
                  errors.addr_line1 ? "is-invalid" : ""
                }`}
              />
              {errors.addr_line1 && (
                <Form.Control.Feedback type="invalid">
                  {errors.addr_line1.message}
                </Form.Control.Feedback>
              )}
              <Form.Control
                type="text"
                name="addr_line2"
                autoComplete="off"
                ref={register}
                defaultValue="-"
                className={`form-control ${
                  errors.addr_line2 ? "is-invalid" : ""
                }`}
              />
              {errors.addr_line2 && (
                <Form.Control.Feedback type="invalid">
                  {errors.addr_line2.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <br />
            <Button variant="primary" type="submit">
              เพิ่มลุกค้า
            </Button>

            <Button
              className="ml-3"
              variant="secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              กลับ
            </Button>
          </Form>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateCustomer;
